@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.strategy {
    height: 270vh;
    z-index: 10000;

    &__title {
        text-align: center;
        @include title(24, 700, 28px);
        margin-bottom: 30px;
    }

    &__desc {
        @include title(24px, 400, 28px);
        max-width: 505px;
        text-align: center;
        margin: 0 auto;
    }

    &__wrap {
        width: 1100px;
        position: relative;
        @include flexStyle(none, center, none);
        margin: 0 auto;
    }

    &__wrap-two {
        display: none;
    }

    &__img-wer {
        display: none;
    }

    &__img-two {
        display: none;
    }

    &__img {
        position: absolute;
        top: 10px;
        width: 1000px;
        height: 2100px;
    }

    &__text {
        position: absolute;
        @include text16();
    }

    &__text:nth-child(1) {
        position: absolute;
        max-width: 356px;
        text-align: center;
        top: 160px;
        left: -12%;
    }

    &__text:nth-child(2) {
        position: absolute;
        max-width: 302px;
        text-align: center;
        top: 175px;
        left: 27%;
    }

    &__text:nth-child(3) {
        position: absolute;
        max-width: 218px;
        text-align: center;
        top: 205px;
        left: 60%;
    }

    &__text:nth-child(4) {
        width: 200px;
        text-align: center;
        top: 205px;
        left: 85%;
    }

    &__text:nth-child(5) {
        width: 340px;
        top: 680px;
        left: 5%;
    }

    &__text:nth-child(6) {
        width: 200px;
        top: 800px;
        left: 28%;
    }

    &__text:nth-child(7) {
        width: 335px;
        top: 800px;
        left: 65%;
    }

    &__text:nth-child(8) {
        width: 275px;
        top: 920px;
        left: 55%;
    }

    &__text:nth-child(9) {
        width: 305px;
        top: 927px;
        left: 12%;
    }

    &__text:nth-child(10) {
        width: 240px;
        top: 1040px;
        left: 25%;
    }

    &__text:nth-child(11) {
        width: 255px;
        top: 1045px;
        left: 65%;
    }

    &__text:nth-child(12) {
        width: 280px;
        top: 1160px;
        left: 56%;
    }

    &__text:nth-child(13) {
        width: 319px;
        top: 1165px;
        text-align: right;
        left: 6%;
    }

    &__text:nth-child(14) {
        width: 280px;
        top: 1270px;
        text-align: right;
        left: 20%;
    }

    &__text:nth-child(15) {
        width: 280px;
        top: 1395px;
        left: 55%;
    }

    &__text:nth-child(16) {
        width: 200px;
        top: 1410px;
        left: 19%;
        text-align: right;
    }

    &__text:nth-child(17) {
        width: 260px;
        top: 1520px;
        left: 20%;
        text-align: right;
    }

    &__text:nth-child(18) {
        width: 291px;
        top: 1530px;
        left: 63%;
    }

    &__text:nth-child(19) {
        width: 265px;
        top: 1640px;
        left: 55%;
    }

    &__text:nth-child(20) {
        width: 348px;
        top: 1650px;
        left: 5%;
        text-align: right;
    }

    &__text:nth-child(21) {
        width: 250px;
        top: 1790px;
        left: 22%;
        text-align: right;
    }
}

@media only screen and (max-width: 1200px) {
    .strategy {
        height: 230vh;

        &__wrap {
            width: 900px;
            height: 100%;
        }

        &__img {
            display: none;
        }

        &__img-two {
            display: flex;
        }

        &__img-two {
            position: absolute;
            top: 40%;
            left: 45%;
        }

        &__text:nth-child(1) {
            max-width: 400px;
            top: 120px;
            left: 28%;
        }

        &__text:nth-child(1)::before {
            content: "";
            position: absolute;
            top: -55%;
            left: 47%;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #4EBA6F;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), inset 0px 8px 10px #62D987;
        }

        &__text:nth-child(2) {
            position: absolute;
            max-width: 350px;
            text-align: center;
            top: 335px;
            left: 31%;
        }

        &__text:nth-child(2)::before {
            content: "";
            position: absolute;
            top: -65%;
            left: 47%;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #6C63FF;
            box-shadow: 0px -5.53846px 11.0769px rgba(0, 0, 0, 0.25);
        }

        &__text:nth-child(3) {
            position: absolute;
            max-width: 218px;
            text-align: center;
            top: 515px;
            left: 38%;
        }

        &__text:nth-child(3)::before {
            content: "";
            position: absolute;
            top: -80%;
            left: 46%;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #F25A59;
            box-shadow: 0px 5.53846px 11.0769px rgba(0, 0, 0, 0.25);
        }

        &__text:nth-child(4) {
            width: 250px;
            text-align: center;
            top: 675px;
            left: 36.3%;
        }

        &__text:nth-child(4)::before {
            content: "";
            position: absolute;
            top: -80%;
            left: 46%;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background: #955BA5;
            box-shadow: 0px 5.53846px 11.0769px rgba(0, 0, 0, 0.25);
        }

        &__text:nth-child(5) {
            width: 340px;
            top: 36.5%;
            left: 6%;
        }

        &__text:nth-child(6) {
            width: 240px;
            top: 42.9%;
            left: 20%;
        }

        &__text:nth-child(7) {
            width: 375px;
            top: 37.1%;
            left: 58%;
        }

        &__text:nth-child(8) {
            width: 340px;
            top: 43%;
            left: 58%;
        }

        &__text:nth-child(9) {
            width: 340px;
            top: 48.5%;
            left: 9%;
        }

        &__text:nth-child(10) {
            width: 340px;
            top: 55.5%;
            left: 10%;
        }

        &__text:nth-child(11) {
            width: 340px;
            top: 49.5%;
            left: 58%;
        }

        &__text:nth-child(12) {
            width: 340px;
            top: 55.9%;
            left: 59%;
        }

        &__text:nth-child(13) {
            width: 340px;
            top: 62%;
            left: 5%;
        }

        &__text:nth-child(14) {
            width: 340px;
            top: 68%;
            left: 5%;
        }

        &__text:nth-child(15) {
            width: 340px;
            top: 62.9%;
            left: 58%;
        }

        &__text:nth-child(16) {
            width: 340px;
            top: 73%;
            left: 5%;
        }

        &__text:nth-child(17) {
            width: 340px;
            top: 79%;
            left: 5%;
        }

        &__text:nth-child(18) {
            width: 340px;
            top: 69%;
            left: 58%;
        }

        &__text:nth-child(19) {
            width: 340px;
            top: 72%;
            left: 58%;
        }

        &__text:nth-child(20) {
            width: 340px;
            top: 79%;
            left: 55%;
        }

        &__text:nth-child(21) {
            width: 340px;
            top: 87%;
            left: 56%;
        }
    }
}

@media only screen and (max-width: 900px) {
    .strategy {
        &__wrap {
            width: 500px;
            height: 100%;
        }

        &__img-two {
            top: 40%;
            left: 40%;
        }

        &__text {
            font-size: 12px;
        }

        &__text:nth-child(1) {
            top: 100px;
            left: 8%;
        }

        &__text:nth-child(2) {
            top: 320px;
            left: 13%;
        }

        &__text:nth-child(2)::before {
            top: -80%;
            left: 47%;
        }

        &__text:nth-child(3) {
            top: 500px;
            left: 26%;
        }

        &__text:nth-child(4) {
            top: 660px;
            left: 23.3%;
        }


        &__text:nth-child(4)::before {
            top: -120%;
            left: 47%;
        }

        &__text:nth-child(5) {
            max-width: 250px;
            top: 37.5%;
            left: -5%;
        }

        &__text:nth-child(6) {
            width: 240px;
            top: 44.5%;
            left: -6%;
        }

        &__text:nth-child(7) {
            width: 250px;
            top: 38.1%;
            left: 61%;
        }

        &__text:nth-child(8) {
            width: 270px;
            top: 44%;
            left: 62%;
        }

        &__text:nth-child(9) {
            width: 200px;
            top: 49.5%;
            left: -2%;
        }

        &__text:nth-child(10) {
            width: 200px;
            top: 56.5%;
            left: 2%;
        }

        &__text:nth-child(11) {
            width: 200px;
            top: 49.5%;
            left: 61%;
        }

        &__text:nth-child(12) {
            width: 200px;
            top: 55.9%;
            left: 62%;
        }

        &__text:nth-child(13) {
            width: 200px;
            top: 62%;
            left: -2%;
        }

        &__text:nth-child(14) {
            width: 200px;
            top: 68%;
            left: -2%;
        }

        &__text:nth-child(15) {
            width: 230px;
            top: 62.9%;
            left: 62%;
        }

        &__text:nth-child(16) {
            width: 200px;
            top: 73%;
            left: -3%;
        }

        &__text:nth-child(17) {
            width: 200px;
            top: 79%;
            left: -3%;
        }

        &__text:nth-child(18) {
            width: 200px;
            top: 68%;
            left: 62%;
        }

        &__text:nth-child(19) {
            width: 230px;
            top: 72%;
            left: 63%;
        }

        &__text:nth-child(20) {
            width: 250px;
            top: 79%;
            left: 62%;
            text-align: left;
        }

        &__text:nth-child(21) {
            width: 200px;
            top: 87%;
            left: 63%;
            text-align: left;
        }
    }
}

@media only screen and (max-width: 768px) {
    .strategy {
        &__desc {
            @include title(18px, 400, 28px);
            max-width: 700px;
            text-align: center;
            margin: 0 auto;
        }

    }
}

@media only screen and (max-width: 650px) {
    .strategy {
        height: 550vh;

        &__wrap {
            display: none;
        }

        &__wrap-two {
            display: flex;

            ul {
                padding-top: 100px;

                li p{
                    position: relative;
                }

                li p::before {
                    content: "";
                    position: absolute;
                    top: -100%;
                    left: 45%;
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    background: #4EBA6F;
                    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25), inset 0px 8px 10px #62D987;
                }

                li+li {
                    margin-top: 150px;
                }
            }

            ul li p {
                height: 50px;
                text-transform: uppercase;
                text-align: center;
            }
        }
    }
}

@media only screen and (max-width: 490px) {
    .strategy {
        // height: 110vh;

        &__img-wer {
            margin-top: 30px;
        }

        &__desc {
            @include title(16px, 400, 28px);
        }
    }
}