@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.projects {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__title {
        position: relative;
        @include title(28px, 700, 155%);
        margin-bottom: 10px;
        text-align: center;
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
    }

    &__wrap-title {
        position: absolute;
        top: -150%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%)
    }

    &__desc {
        @include title(24px, 400, 155%);
        max-width: 797px;
        text-align: center;
        margin: 0 auto;
    }
}

@media only screen and (max-width: 900px) {
    .projects {
        &__wrap-title {
            top: -100%;
            @include title(90px, 700, 155.52%)
        }
    }
}

@media only screen and (max-width: 768px) {
    .projects {
        &__wrap-title {
            top: -60%;
            @include title(60px, 700, 155.52%)
        }

        &__desc {
            @include title(18px, 400, 155%);
        }
    }
}

@media only screen and (max-width: 500px) {
    .projects {
        display: none;
    }
}

@media only screen and (max-width: 368px) {
    .projects {
        &__wrap-title {
            top: -65%;
            @include title(45px, 700, 155.52%)
        }
    }
}