@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.infrastructure {
    height: 100vh;

    &__wrap {
        height: 1000px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__title {
        @include title(28px, 700, 32px);
        text-align: center;
        // margin-bottom: 20px;
    }

    &__desc {
        @include title(24px, 400, 28px);
        max-width: 670px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 65px;
    }

    &__wrapper {
        width: 1280px;
        height: 450px;
        @include flexStyle(center, none, none);
        margin: 0 auto;
        margin-bottom: 50px;
    }

    &__wrapper p {
        @include title(24px, 400, 160%);
        padding: 105px 38px;
        background: rgba(108, 99, 255, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 0px 5px 5px 0px;
    }

    &__list {
        @include flexStyle(center, none, none);
    }

    &__list {
        li + li {
            margin-left: 10px;
        }
    }
}