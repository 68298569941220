@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.service-price {
    height: 100vh;
    position: relative;
    @include flexStyle(center, center, column);

    &__title {
        @include title(28px, 700, 32px);
        text-align: center;
        margin-bottom: 60px;
    }

    &__wrapper {
        margin: 0 auto;
    }

    &__list {
        @include flexStyle(center, center, none);
        flex-wrap: wrap;
        grid-gap: 70px 70px;
    }

    &__item {
        @include flexStyle(center, space-between, column);
        width: 335px;
        height: 470px;
        padding: 20px 15px;
        background: rgba(255, 255, 255, 0.2);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(10px);
        border-radius: 20px;

        div {
            h4 {
                @include title(22px, 700, 25px);
                text-align: center;
                margin-bottom: 45px;
            }

            .service-price__list-two {
                @include flexStyle(center, none, column);
                text-align: center;

                li {
                    margin-bottom: 15px;
                }

                p {
                    @include title(14px, 400, 16px);
                }
            }
        }
    }

    &__box-two {
        text-align: center;

        h5 {
            @include title(18px, 700, 21px);
            margin-bottom: 15px;
        }

        button {
            @include button();
            background: #6C63FF;
            border: 1px solid #6C63FF;
            border-radius: 50px;
        }
    }
}

@media only screen and (max-width: 1185px) {
    .service-price {
        height: 170vh;
    }
}

@media only screen and (max-width: 1030px) {
    .service-price {
        padding: 150px 0px;
        height: 100%;
    }
}

@media only screen and (max-width: 505px) {
    .service-price {
        &__item {
            width: 100%;
            min-height: 400px;
            height: auto;
            padding: 15px 15px;
        }
    }
}

@media only screen and (max-width: 390px) {
    .service-price {
        padding: 0px 0px 100px 0px;
    }
}