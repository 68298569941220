@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.digitizationcard {
    height: 100vh;
    @include flexStyle(center, center, column);

    ul {
        @include flexStyle(center, center, none);
        flex-wrap: wrap;
        width: 800px;
        text-align: center;
        grid-gap: 50px 70px;

        li {
            img {
                width: 350px;
                height: 300px;
                border-radius: 5px;
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .digitizationcard {
        padding-top: 100px;

        height: 100%;

        ul {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 450px) {
    .digitizationcard {
        padding-top: 110px;
        padding-bottom: 100px;

        ul {
            width: 100%;

            li {
                img {
                    width: 300px;
                    height: 250px;
                }
            }
        }
    }
}

@media only screen and (max-width: 368px) {
    .digitizationcard {
        padding-top: 110px;
        padding-bottom: 150px;

        ul {
            width: 100%;

            li {
                img {
                    width: 250px;
                    height: 220px;
                }
            }
        }
    }
}



