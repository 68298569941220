#brands {
    padding-top: 100px;
    .orb {
        width: 800px;
        height: 200px;
        display: inline-block;
        margin: 0 50px;
        transition: all 0.3s ease-out;
    }

    .orb img {
        height: 275px;
        width: 500px;
        object-fit: cover;
        border-radius: 10px;
    }

    .orb:hover {
        transform: scale(1.1);
        cursor: pointer;
    }

    .orb {
        a {
            display: flex;
            align-items: center;
            width: 800px;
        }

        p {
            font-family: 'Play';
            font-style: normal;
            font-weight: 400;
            font-size: 22px;
            line-height: 25px;
            color: #FFFFFF;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            margin-left: 50px;
        }
    }

    .marquee {
        height: 500px;
        width: 1200px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        margin: auto;
    }

    .marquee--inner {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 50%;
        margin: 70px 0;
        position: absolute;
        animation: marquee 30s linear infinite;
    }

    .marquee--inner:hover {
        animation-play-state: paused;
    }

    .half {
        float: left;
        width: 10%;
    }

    span {
        float: left;
        width: 150%;
    }

    @keyframes marquee {
        0% {
            left: 0;
        }

        100% {
            left: -100%;
        }
    }
}

@media only screen and (max-width: 1240px) {
    #brands {
        .marquee {
            height: 500px;
            width: 900px;
        }

        .orb {
            width: 1000px;
            height: 200px;
        }

        span {
            float: left;
            width: 200%;
        }
    }
}

@media only screen and (max-width: 900px) {
    #brands {
        .marquee {
            height: 400px;
            width: 100%;
        }

        span {
            width: 250%;
        }
    }
}

@media only screen and (max-width: 768px) {
    #brands {
        .marquee {
            height: 350px;
            width: 100%;
        }

        span {
            width: 350%;
        }

        .orb {
            width: 800px;
            height: 150px;
        }
    
        .orb img {
            height: 200px;
            width: 300px;
        }
    }
}

@media only screen and (max-width: 525px) {
    #brands {
        .marquee {
            height: 350px;
            width: 100%;
        }

        span {
            width: 350%;
        }

        .orb {
            width: 800px;
            height: 150px;
        }
    
        .orb img {
            height: 150px;
            width: 250px;
        }

        .orb {
            a {
                display: flex;
                align-items: center;
                width: 600px;
            }
    
            p {
                font-size: 18px;
                line-height: 30px;
                margin-left: 30px;
            }
        }
    }
}

@media (max-width: 430px) {
    #brands {
        .orb {
            width: 100px;
            height: 200px;
            display: inline-block;
            margin: 0 15px;
            transition: all 0.3s ease-out;
        }

        .orb img {
            height: 80px;
            object-fit: contain;
        }

        .orb:hover {
            transform: scale(1.1);
            opacity: 0.8;
            cursor: pointer;
        }

        .marquee {
            height: 200px;
            width: 100%;
            overflow: hidden;
            position: relative;
            margin: auto;
        }

        .marquee--inner {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 200%;
            margin: 20px 0;
            position: absolute;
            animation: marquee 20s linear infinite;
        }

        .marquee--inner:hover {
            animation-play-state: paused;
        }

        .half {
            float: left;
            width: 10%;
        }
    }
}