@import "../../Assets/Scss/Style.scss";

.form-modal {   
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    z-index: 1;

    &__form {
        position: relative;
        @include flexStyle(center, center, column);
        padding: 70px;
        width: 500px;
        height: 700px;
        transition: all 0.7s;
        transform: scale(0);
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(35px);
        border-radius: 20px;
        pointer-events: auto;
        z-index: 11111;

        input {
            @include formInput();
            color: #fff;
            font-size: 20px;
            font-family: sans-serif;
        }

        input~input {
            margin-top: 50px;
        }

        input::placeholder {
            font-size: 18px;
        }

        textarea {
            margin: 50px 0px;
            color: #fff;
            font-size: 20px;
            font-family: sans-serif;
            @include formInput();
        }

        textarea::placeholder {
            font-size: 18px;
        }
    }

    &__btn-clos {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 24px;
        color: #fff;
        border: none;
        background-color: transparent;
        cursor: pointer;
    }

    &__btn {
        @include button();
        border: none;
        background: #6C63FF;
    }
}

.form-modal.open--modal {
    opacity: 1;
    pointer-events: all;

.form-modal__form {
        transform: scale(1);
    }
}

@media only screen and (max-width: 768px) {
    .form-modal {
        &__form {
            padding: 60px;
            width: 400px;
            height: 600px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .form-modal {
        &__form {
            padding: 60px 40px;
            width: 300px;

            input {
                font-size: 18px;
            }
    
            input~input {
                margin-top: 40px;
            }
    
            input::placeholder {
                font-size: 16px;
            }
    
            textarea {
                margin: 40px 0px;
                color: #fff;
            }
    
            textarea::placeholder {
                font-size: 16px;
            }
        }
    }
}
