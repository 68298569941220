@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.itacademy {
    height: 100%;

    &__title {
        @include title(45px, 700, 52px);
        margin-bottom: 45px;
        text-align: center;
    }

    &__desc {
        @include title(24px, 400, 28px);
        margin: 0 auto;
        margin-bottom: 100px;
        text-align: center;
        max-width: 320px;
    }

    &__content {
        padding-bottom: 150px;
    }

    &__list {
        width: 100%;
        @include flexStyle(center, center, none);
        margin: 0 auto;
        flex-wrap: wrap;
        grid-gap: 80px 50px;
    }

    &__item {
        @include flexStyle(center, none, none);
        position: relative;
        width: 550px;
        height: 250px;
        padding: 15px 30px;
        box-shadow: inset 0px 4px 30px rgba(255, 255, 255, 0.4);
        border-radius: 11.4085px;

        h4 {
            @include title(28px, 700, 155%);
            margin-bottom: 10px;
            z-index: 10;
        }

        h5 {
            @include title(18px, 700, 155%);
            margin-bottom: 10px;
            z-index: 10;
        }

        p {
            @include title(16px, 400, 155%);
            margin-bottom: 10px;
            z-index: 10;

            span {
                @include title(16px, 700, 155%);
                margin-left: 5px;
                margin-right: 5px;
            }
        }

        button {
            @include button();
            margin-top: 10px;
        }

        img {
            position: absolute;
            top: -40px;
            right: -15px;
            z-index: -1;
        }

        img {
            animation: updown 5s linear infinite;
        }

        @keyframes updown {
            0% {
                transform: translateY(-20px);
            }

            50% {
                transform: translateY(20px);
            }

            100% {
                transform: translateY(-20px);
            }
        }
    }

    &__item::before {
        content: "";
        width: 88px;
        height: 176px;
        position: absolute;
        right: 0px;
        background: url("../../../Assets/Icon/elips.svg") no-repeat;
    }

    &__item:nth-child(1) {
        background-color: #389A65;
    }

    &__item:nth-child(2) {
        background-color: #9417C0;
        background-position: 0px 30px;
        // transform: ;
    }

    &__item:nth-child(2)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(3) {
        background-color: #26AAD4;
    }

    &__item:nth-child(4) {
        background-color: #315ED0;
    }

    &__item:nth-child(6) {
        background-color: #b631d0;
    }

    &__item:nth-child(6)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(7) {
        background-color: #d06631;
    }

    &__item:nth-child(8) {
        background-color: #31d076;
    }

    &__item:nth-child(8)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(9) {
        background-color: #31c5d0;
    }

    &__item:nth-child(10) {
        background-color: #d07131;
    }

    &__item:nth-child(10)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(11) {
        background-color: #66d031;
    }

    &__item:nth-child(12) {
        background-color: #3e0786;
    }

    &__item:nth-child(12)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(13) {
        background-color: #7f0e7b;
    }

    &__item:nth-child(4)::before {
        left: 0px;
        transform: rotate(180deg);
    }

    &__item:nth-child(5) {
        background-color: #CBA315;

        img {
            right: 25px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .itacademy {

        &__desc {
            max-width: 100%;
        }

        &__item {
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .itacademy {

        &__title {
            @include title(35px, 700, 52px);
            margin-bottom: 25px;
        }

        &__desc {
            @include title(18px, 400, 28px);
            max-width: 100%;
            margin-bottom: 80px;
        }

        &__item {
            width: 100%;
            height: 250px;
        }
    }
}

@media only screen and (max-width: 585px) {
    .itacademy {

        &__title {
            @include title(35px, 700, 52px);
            margin-bottom: 25px;
        }

        &__desc {
            @include title(18px, 400, 28px);
            max-width: 100%;
            margin-bottom: 80px;
        }

        &__item {
            width: 100%;
            height: 240px;
        }
    }
}

@media only screen and (max-width: 510px) {
    .itacademy {
        &__item {
            img {
                width: 250px;
                height: 200px;
            }
        }

        &__item:nth-child(5) {
            background-color: #CBA315;

            img {
                right: -10px;
            }
        }
    }
}

@media only screen and (max-width: 460px) {
    .itacademy {
        &__item {
            height: 250px;

            img {
                width: 200px;
                height: 150px;
                top: -20px;
            }
        }

        &__item-left {
            z-index: 1;
        }

        &__item:nth-child(5) {
            background-color: #CBA315;

            img {
                right: -10px;
            }
        }
    }
}