.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 11111;
    background-color: rgb(0, 0, 0);
}

.loader__wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader__top {
    position: relative;
    width: 480px;
}

.logo1 {
    margin-right: 50px;
    animation: fadeInLeft;
    animation-duration: 1s;
}

.logo2 {
    position: absolute;
    top: 5%;
    animation: fadeInRight;
    animation-duration: 1.5s;
}

.logo3 {
    position: absolute;
    top: 58%;
    animation: fadeInRight;
    animation-duration: 2s;
}

.loader__container {
    width: 250px;
}

.line {
    height: 3px;
    width: 100%;
    background: #e8e8e8;
    margin-top: 25%;

    .inner {
        width: 100%;
        height: inherit;
        background: #0077B5;
        animation: slide 2s ease-in-out infinite;
    }
}

@keyframes slide {
    0% {
        transform-origin: left;
        transform: scalex(0.3);
    }

    //12.5%{
    //transform-origin: left;
    //transform: scalex(0.2);
    //}
    25% {
        transform-origin: left;
        transform: scalex(1);
    }

    26% {
        transform-origin: right;
        transform: scalex(1);
    }

    50% {
        transform-origin: right;
        transform: scalex(0.3);
    }

    //62.5%{
    //transform-origin: right;
    //transform: scalex(0.2);
    //}
    75% {
        transform-origin: right;
        transform: scalex(1);
    }

    76% {
        transform-origin: left;
        transform: scalex(1);
    }

    100% {
        transform-origin: left;
        transform: scalex(0.3);
    }
}

// #logo path:nth-child(1) {
//     stroke-dasharray: 396;
//     stroke-dashoffset: 10;
// }

#logo path:nth-child(2) {
    stroke-dasharray: 396;
    stroke-dashoffset: 396;
    animation: line-anime 20s ease forwards;
}

#logo path:nth-child(3) {
    stroke-dasharray: 200;
    stroke-dashoffset: 200;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(4) {
    stroke-dasharray: 481;
    stroke-dashoffset: 481;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(5) {
    stroke-dasharray: 170;
    stroke-dashoffset: 170;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(6) {
    stroke-dasharray: 353;
    stroke-dashoffset: 353;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(7) {
    stroke-dasharray: 353;
    stroke-dashoffset: 353;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(8) {
    stroke-dasharray: 218;
    stroke-dashoffset: 218;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(9) {
    stroke-dasharray: 396;
    stroke-dashoffset: 396;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(10) {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(11) {
    stroke-dasharray: 481;
    stroke-dashoffset: 481;
    animation: line-anime 10s ease forwards;
}

#logo path:nth-child(12) {
    stroke-dasharray: 351;
    stroke-dashoffset: 351;
    animation: line-anime 10s ease forwards;
}

@keyframes line-anime {
    to {
        stroke-dashoffset: 0;
    }
}