@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.homhero {
    position: relative;
    @include flexStyle(start, center, column);
    margin-right: 100px;
    height: 100vh;

    &__modal-box {
        position: absolute;
    }

    &__asidemenu-wrap {
        position: fixed;
        left: 10%;
    }

    &__box {
        @include flexStyle(center, none, none);
    }

    &__title {
        width: 590px;
        height: 295px;
        @include title(123px, 700, 142px);
        margin-bottom: 109px;
        animation: fadeInUp;
        animation-duration: 3s;
    }

    &__tell-wrap {
        position: fixed;
        right: 10%;
        top: 80%;
    }

    &__btn {
        @include button();
    }

    &__btn-video {
        margin-left: 310px;
        width: 87px;
        height: 87px;
        border: none;
        border-radius: 50%;
        background: url("../../Assets/Img/play.svg") no-repeat;
        background-size: cover;
        cursor: pointer;
        animation: zoomIn;
        animation-duration: 3s;
    }

    .btn:hover {
        animation: none;
    }

    &__btn-video {
        animation: pulse 2s infinite 3s cubic-bezier(0.25, 0, 0, .15);
        box-shadow: 0 0 0 0 white;
        animation: pulse;
        animation-duration: 3s;
    }

    @keyframes pulse {
        to {
            box-shadow: 0 0 0 25px rgba(108, 99, 255, 0.15);
        }
    }
}

@media(max-width: 1140px) {
    .homhero {
    margin-right: 0px;

        &__btn-video {
            margin-left: 200px;
        }

        &__asidemenu-wrap {
            display: none;
        }

        &__tell-wrap {
            display: none;
        }
    }
}

@media(max-width: 1030px) {
    .homhero {
        &__asidemenu-wrap {
            display: none;
        }

        @include flexStyle(center, center, column);

        &__btn-video {
            margin-left: 100px;
        }
    }
}


@media(max-width: 900px) {
    .homhero {
        &__box {
            @include flexStyle(center, none, column);
        }

        &__btn {
            display: none;
        }

        &__btn-video {
            margin-left: 0px;
        }

        &__title {
            max-width: 590px;
            text-align: center;
            @include title(100px, 700, 120px);
        }
    }
}


@media (max-width: 768px) {
    .homhero {
        &__title {
            width: 100%;
            @include title(100px, 700, 120px);
            margin-bottom: 50px;
        }
    }
}

@media (max-width: 520px) {
    .homhero {
        &__title {
            width: 100%;
            height: 200px;
            @include title(70px, 700, 90px);
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 368px) {
    .homhero {
        &__title {
            width: 100%;
            height: 200px;
            @include title(60px, 700, 90px);
            margin-bottom: 20px;
        }
    }
}