@import "../../Assets/Scss/Style.scss";

.tad {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        // width: 800px;
        margin: 0 auto;
    }

    &__bg-title {
        @include title(111px, 700, 155%);
        position: absolute;
        top: -70%;
        // left: 11%;
        color: rgba(255, 255, 255, 0.04);
    }

    &__title {
        @include title(28px, 700, 155%);
        margin-bottom: 70px;
    }

    &__box {
        width: 1160px;
        @include flexStyle(center, space-between, none);
    }

    &__img {
        width: 500px;
        height: 400px;
        animation: shake 5s linear 0s infinite;
    }

    &__desc {
        max-width: 570px;
        @include title(24px, 400, 37px);
        margin-left: 100px;
        margin-bottom: 70px;
    }

    &__btn-link {
        @include button();
        margin-left: 100px;
    }

    @keyframes shake {
        0% {
            transform: rotate(0deg) translate3d(0, 0, 0);
        }

        25% {
            transform: rotate(10deg) translate3d(0, 0, 0);
        }

        50% {
            transform: rotate(0deg) translate3d(0, 0, 0);
        }

        75% {
            transform: rotate(-10deg) translate3d(0, 0, 0);
        }

        100% {
            transform: rotate(0deg) translate3d(0, 0, 0);
        }
    }
}

@media only screen and (max-width: 1160px) {
    .tad {
        &__box {
            width: 900px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .tad {
        height: 120vh;

        &__bg-wrap {
            @include flexStyle(center, center, column);
            // width: 600px;
        }

        &__bg-title {
            @include title(90px, 700, 155%);
            top: -70%;
        }

        &__box {
            width: 100%;
            text-align: center;
            @include flexStyle(center, center, column-reverse);
        }

        &__title {
            margin-bottom: 50px;
        }

        &__desc {
            margin-left: 0px;
            margin-bottom: 50px;
            max-width: 800px;
        }

        &__btn-link {
            margin-left: 0px;
        }

        &__img {
            margin-top: 70px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .tad {
        height: 120vh;

        &__bg-wrap {
            width: 400px;
        }

        &__bg-title {
            @include title(60px, 700, 155%);
            top: -50%;
        }

        &__box {
            width: 100%x;
        }

        &__desc {
            @include title(18px, 400, 28px);
            margin-left: 0px;
            margin-bottom: 50px;
            max-width: 100%;
        }

        &__btn-link {
            margin-left: 0px;
        }

        &__img {
            width: 100%;
            height: auto;
            margin-top: 70px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .tad {
        height: 100vh;

        &__bg-wrap {
            width: 100%;
        }

        &__bg-title {
            @include flexStyle(center, center, center);
            @include title(45px, 700, 155%);
            top: -40%;
        }

        &__title {
            margin-bottom: 20px;
        }

        &__img {
            width: 100%;
            height: auto;
        }
    }
}