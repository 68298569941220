@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.group-cyber {
    @include flexStyle(center, center, column);
    height: 100vh;

    &__content {
        margin-top: 40px;
        @include flexStyle(center, space-between, none);
    }

    &__box {
        text-align: center;
    }

    &__box h4 {
        @include title(24px, 700, 155%);
        margin-bottom: 50px;
    }

    &__box p {
        @include title(20px, 700, 155%);
        max-width: 1000px;
        margin-bottom: 50px;
    }

    &__link {
        width: min-content;
        @include button();
        @include flexStyle(flex-end, flex-end, none);
    }

    &__wrap-btn {
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-width: 1030px) {
    .group-cyber {

        height: 120vh;

        &__content {
            margin-top: 40px;
            @include flexStyle(center, space-between, column-reverse);

            img {
                margin-top: 50px;
            }
        }

        &__box {
            div {
                @include flexStyle(center, center, column);
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .group-cyber {
        height: 120vh;

        &__content {
            margin-top: 40px;
            @include flexStyle(center, space-between, column-reverse);

            img {
                width: 100%;
                height: auto;
                min-height: 300px;
            }
        }

        &__box h4 {
            margin-bottom: 30px;
        }

        &__box p {
            @include title(18px, 400, 155%);
            max-width: 100%;
            margin-bottom: 30px;
        }
    }
}

@media only screen and (max-width: 368px) {
    .group-cyber {
        height: 100vh;
    }
}