@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.business {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__title {
        position: relative;
        text-align: center;
        @include title(28px, 700, 155%);
        margin-bottom: 51px;
    }

    &__bg-title {
        position: absolute;
        top: -80%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__box {
        @include flexStyle(center, space-between, none);
    }

    &__desc {
        max-width: 532px;
        @include title(24px, 400, 155%);
        margin-bottom: 70px;
    }

    &__link-btn {
        @include button();
    }
}

@media only screen and (max-width: 1030px) {
    .business {
        &__img {
            width: 100%;
            height: auto;
        }

        &__bg-title {
            top: -70%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 900px) {
    .business {
        height: 120vh;

        &__box {
            text-align: center;
            @include flexStyle(center, center, column);
        }

        &__img {
            margin-top: 50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .business {
        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -50%;
            @include title(60px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 540px) {
    .business {
        &__bg-title {
            top: -30%;
            @include title(45px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 440px) {
    .business {

        &__title {
            margin-bottom: 20px;
        }

        &__bg-title {
            top: -20%;
            @include title(40px, 700, 155.52%);
        }
    }
}