@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.aside-menu {
    &__list {
        margin-right: 58px;
    }

    &__item + &__item {
        margin-top: 20px;
    }

    &__item:nth-child(1) {
        animation: bounceInLeft;
        animation-duration: 1s;
    }

    &__item:nth-child(2) {
        animation: bounceInLeft;
        animation-duration: 2s;
    }
    &__item:nth-child(3) {
        animation: bounceInLeft;
        animation-duration: 3s;
    }
    &__item:nth-child(4) {
        animation: bounceInLeft;
        animation-duration: 4s;
    }

    &__item-link {
        @include flexStyle(center, center, none);
        width: 42px;
        height: 42px;
        border-radius: 50%;
        background: #ffffff1a;
        transition: ease 0.5s;
    }

    &__item-icon {
        transition: ease 0.8s;
        transform: rotate(0deg);
        animation: shakeX;
        animation-duration: 3s;
    }

    &__item-link:hover {
        background: rgba(255, 255, 255, 0.5);
    }

    &__item-link:hover > &__item-icon {
        transform: rotate(720deg);
        
    }
}