@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.ittalimcom {
    height: 100vh;
    padding-top: 100px;
    @include flexStyle(center, center, column);

    video {
        border-radius: 20px !important;
        background-size: cover;
        object-fit: cover;
    }

    &__video {
        width: 800px !important;
        height: 500px !important;
        border-radius: 20px !important;
    }

    &__content-text {
        width: 800px;
        height: 500px;
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
    }

    &__title {
        position: relative;
        @include title(28px, 700, 155%);
        margin: 20px 0px 50px 0px;
    }

    &__bg-title {
        position: absolute;
        top: -50%;
        color: rgba(255, 255, 255, 0.08);
        @include title(111.724px, 700, 155.52%);
    }

    &__content {
        display: flex;
        position: relative;
    }

    &__asidemenu-wrap {
        position: fixed;
        top: 40%;
        left: 10%;
    }

    &__wrap {
        text-align: center;
    }

    &__tel-wrap {
        position: fixed;
        top: 80%;
        right: 10%;
    }
}

@media only screen and (max-width: 1260px) {
    .ittalimcom {
        &__content-text {
            width: 800px;
        }
    }
}

@media only screen and (max-width: 1160px) {
    .ittalimcom {
        &__asidemenu-wrap {
            display: none;
        }

        &__tel-wrap {
            display: none;
        }
    }
}

@media only screen and (max-width: 890px) {
    .ittalimcom {
        &__title {
            margin: 20px 0px 50px 0px;
        }

        &__bg-title {
            top: -30%;
            @include title(90px, 700, 155.52%);
        }

        &__content-text {
            width: 600px;
        }

        &__video {
            width:100% !important;
            height: 500px !important;
            border-radius: 20px;
        }
    
    }
}

@media only screen and (max-width: 670px) {
    .ittalimcom {
        &__title {
            @include title(24px, 700, 155%);
            margin: 10px 0px 40px 0px;
        }

        &__bg-title {
            top: -20%;
            @include title(60px, 700, 155.52%);
        }

        &__content-text {
            max-width: 100%;
            width: 450px;
        }
    }
}

@media only screen and (max-width: 485px) {
    .ittalimcom {
        &__bg-title {
            top: -20%;
            @include title(60px, 700, 155.52%);
        }

        &__content-text {
            max-width: 100%;
            width: 330px;
            min-height: 450px;
            max-height: auto;
        }
    }
}

@media only screen and (max-width: 368px) {
    .ittalimcom {

        &__title {
            margin-top: 40px;
        }

        &__bg-title {
            top: 5%;
            @include title(50px, 700, 155.52%);
        }

        &__content-text {
            max-width: 100%;
            width: 280px;
        }
    }
}