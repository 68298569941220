@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.cybersport {
    @include flexStyle(center, center, column);
    height: 100vh;

    &__bg-wrap {
        position: relative;
        // width: 500px;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__title {
        position: relative;
        text-align: center;
        @include title(28px, 700, 155%);
        margin-bottom: 15px;
    }

    &__bg-title {
        position: absolute;
        top: -140%;
        // left: -18%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__box {
        @include flexStyle(center, space-between, none);
    }

    &__desc {
        max-width: 700px;
        @include title(24px, 400, 155%);
        margin-bottom: 70px;
    }

    &__link-btn {
        @include button();
    }

    &__img {
        animation-name: myanimation;
        animation-duration: 5s;
        animation-iteration-count: infinite;
    }

    @keyframes myanimation {
        0% {
            transform: scale(0.8)
        }

        50% {
            transform: scale(0.9)
        }

        100% {
            transform: scale(0.8)
        }
    }
}

@media only screen and (max-width: 1030px) {
    .cybersport {
        &__img {
            width: 400px;
            height: auto;
        }
    }
}

@media only screen and (max-width: 900px) {
    .cybersport {

        &__box {
            text-align: center;
            @include flexStyle(center, space-between, column);
        }

        &__desc {
            max-width: 100%;
        }

        &__img {
            margin-top: 50px;
            width: 450px;
            height: auto;
        }

        &__title {
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -60%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 768px) {
    .cybersport {
        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -40%;
            // left: -45%;
            @include title(60px, 700, 155.52%);
        }

        &__img {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 440px) {
    .cybersport {

        &__title {
            margin-bottom: 20px;
        }

        &__bg-title {
            top: -70%;
            @include title(45px, 700, 155.52%);
        }
    }
}