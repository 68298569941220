@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.contact {
    height: 100vh;
    margin: 0 auto;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        position: relative;
        @include title(28, 700, 155%);
        margin-bottom: 65px;
    }

    &__bg-title {
        position: absolute;
        top: -80%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__box {
        @include flexStyle(center, space-between, none);
    }

    &__item {
        @include flexStyle(center, none, none);
    }

    &__item+&__item {
        margin-top: 70px;
    }

    &__item-wrap {
        margin-left: 20px;
    }

    &__item-title {
        @include title(14px, 700, 17px);
        font-family: 'Montserrat';
        margin-bottom: 5px;
    }

    &__item-text {
        @include title(12px, 300, 15px);
        font-family: 'Montserrat';
    }

    &__form {
        position: relative;
        @include flexStyle(center, none, column);
        padding: 54px 60px;
        width: 418px;
        height: 480px;
        margin-left: 150px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 10px;
    }

    &__form-title {
        @include title(22px, 600, 27px);
        font-family: 'Montserrat';
        margin-bottom: 20px;
    }

    &__form-input {
        @include formInput();
        color: #fff;
    }

    &__form-textarea {
        @include formInput();
        resize: none;
    }

    .form-input {
        @include title(16px, 500, 20px);
        color: #fff;
        margin-bottom: 45px;
        transition: ease 0.5s;
    }

    .form-input:focus {
        border-bottom: 1.5px solid #6C63FF;
    }

    .form-input::placeholder {
        @include title(16px, 500, 20px);
        font-family: 'Montserrat';
        color: $gray80;
    }

    &__form-btn {
        @include button();
        font-family: 'Montserrat';
        padding: 11px 56px;
        transition: all 0.5 ease;
    }

    &__form-btn:hover {
        opacity: 0.8;
    }

    &__form-btn:active {
        opacity: 0.6;
    }

    &__form-over {
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        position: absolute;
        width: 418px;
        height: 480px;
        top: 0px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 10px;
        z-index: 11;
        transform: scale(0);
        transition: all 0.5s ease;

        div {
            @include flexStyle(center, center, column);
        }

        p {
            text-align: center;
            color: #fff;
            font-size: 22px;
            z-index: 111;
        }

        button {
            @include button();
        }
    }
}

.contact__form-over.open--over {
    opacity: 1;
    transform: scale(1);
}

@media only screen and (max-width: 900px) {
    .contact {
        height: 120vh;

        &__box {
            @include flexStyle(center, space-between, column-reverse);
        }

        &__form {
            margin-left: 0px;
            margin-bottom: 100px;
        }

        &__list {
            @include flexStyle(center, center, none);
            grid-gap: 50px 50px;
            flex-wrap: wrap;
        }

        &__item+&__item {
            margin-top: 0px;
        }

        &__bg-title {
            top: -50%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 768px) {
    .contact {

        &__bg-title {
            top: -40%;
            @include title(60px, 700, 155.52%);
        }

        &__item {
            img {
                width: 40px;
                height: 40px;
            }
        }
    }
}

@media only screen and (max-width: 460px) {
    .contact {
        &__bg-title {
            top: -30%;
            @include title(45px, 700, 155.52%);
        }

        &__list {
            @include flexStyle(start, center, column);
            grid-gap: 50px 50px;
            flex-wrap: wrap;
        }

        &__form {
            width: 100%;
        }

        &__form-over {
            width: 100%;
    
            p {
                color: #fff;
                font-size: 18px;
            }
        }
    }
}

@media only screen and (max-width: 368px) {
    .contact {
        &__form {
            padding: 30px;
            margin-bottom: 80px;
        }

        &__bg-title {
            top: -30%;
            @include title(35px, 700, 155.52%);
        }

        &__form-title {
            @include title(18px, 600, 27px);
            font-family: 'Montserrat';
            margin-bottom: 40px;
        }
    }
}