@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.digitization-ourcall {
    height: 100vh;
    @include flexStyle(center, center, column);
    padding: 100px 0px;

    &__title {
        @include title(28px, 400, 25px);
        text-align: center;
        margin-bottom: 50px;
    }

    &__form {
        @include flexStyle(center, none, column);
        width: 500px;
        height: auto;
        padding: 50px;
        background: rgba(255, 255, 255, 0.1);
        backdrop-filter: blur(20px);
        border-radius: 10px;

        input {
            @include formInput();
            @include title(18px, 500, 20px);
            transition: border-bottom 0.5s ease;
            color: $white;
        }

        input+input {
            margin-top: 40px;
        }

        input::placeholder {
            @include title(16px, 500, 20px);
        }

        input:focus {
            border-bottom: 1.5px solid #6C63FF;
        }

        textarea {
            @include formInput();
            @include title(18px, 500, 20px);
            font-family: 'play';
            margin: 40px 0px;
            height: 150px;
            resize: none;
            color: $white;
            transition: border-bottom 0.5s ease;
        }

        textarea:focus {
            border-bottom: 1.5px solid #6C63FF;
        }

        button {
            @include button();
        }
    }
}

@media only screen and (max-width: 768px) {
    .digitization-ourcall {
        padding: 150px 0px;

        &__title {
            @include title(24px, 400, 25px);
            margin-bottom: 70px;
        }
    
        &__form {
            width: 400px;
        }
    }
}

@media only screen and (max-width: 445px) {
    .digitization-ourcall {
        &__form {
            width: 350px;
        }
    }
}

@media only screen and (max-width: 390px) {
    .digitization-ourcall {
        &__form {
            padding: 40px;
            width: 290px;
        }
    }
}

@media only screen and (max-width: 360px) {
    .digitization-ourcall {
        &__form {
            padding: 30px;
            width: 250px;
        }
    }
}


