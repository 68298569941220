@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.interests {
    height: 100%;
    @include flexStyle(center, center, column);
    padding-top: 100px;
    padding-bottom: 150px;

    &__title {
        max-width: 500px;
        @include title(24px, 700, 155%);
        text-align: center;
        margin: 0 auto;
        margin-bottom: 50px;
    }

    &__content {
        height: 1000px;
    }

    &__list {
        width: 1030px;
        @include flexStyle(center, center, none);
        margin: 0 auto;
        flex-wrap: wrap;
        grid-gap: 100px 120px;
    }

    li {
        width: 250px;
        height: 260px;
        @include flexStyle(center, none, column);

        img {
            width: 250px;
            height: 200px;
            margin-bottom: 35px;
        }

        p {
            @include title(24px, 400, 155%);
        }
    }
}

@media only screen and (max-width: 1090px) {
    .interests {
        height: 100%;
        padding: 100px 0px;
        &__content {
            height: 100%;
        }

        &__list {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px) {
    .interests {
        height: 100%;
        padding: 80px 0px;
        &__content {
            height: 100%;
        }

        &__list {
            width: 100%;
        }

        ul {
            li {
                img {
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
}