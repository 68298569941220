@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.ouraddress {
    height: 100vh;
    @include flexStyle(center, center, column);
    text-align: center;

    iframe {
        width: 800px;
        height: 450px;
    }

    h2 {
        margin-bottom: 50px;
    }
}

@media only screen and (max-width: 900px) {
    .ouraddress {
        iframe {
            width: 600px;
            height: 500px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .ouraddress {
        iframe {
            width: 500px;
            height: 400px;
        }
    }
}

@media only screen and (max-width: 500px) {
    .ouraddress {
        iframe {
            width: 300px;
            height: 500px;
        }
    }
}