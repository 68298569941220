.react-player-modal {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(2px);
    z-index: 11000;

    .react-player-modal__wrap {
        position: relative;
        width: 800px;
        height: 450px;
        transition: all 0.7s;
        transform: scale(0);
        z-index: 1;
    }

    .react-player-modal__btn {
        font-size: 28px;
        position: fixed;
        color: #fff;
        top: 4%;
        right: 2%;
        border: none;
        background-color: transparent;
        pointer-events: painted;
        cursor: pointer;
    }

}

.open--modal {
    opacity: 1;
    pointer-events: all;

    .react-player-modal__wrap {
        transform: scale(1);
    }
}



@media only screen and (max-width: 815px) {

    .react-player-modal {
        .react-player-modal__wrap {
            width: 600px;
            height: 300px;
        }
    
        .react-player-modal__btn {
            top: 10px;
            right: 15px;
            font-size: 24px;
        }
    }
    
}

@media only screen and (max-width: 610px) {

    .react-player-modal {
        .react-player-modal__wrap {
            width: 400px;
            height: 200px;
        }
    
        .react-player-modal__btn {
            top: 10px;
            right: 10px;
            font-size: 20px;
        }
    }
}

@media only screen and (max-width: 400px) {

    .react-player-modal {
        .react-player-modal__wrap {
            width: 300px;
            height: 200px;
        }
    
        .react-player-modal__btn {
            top: 22px;
            right: 5px;
            font-size: 20px;
        }
    }
}