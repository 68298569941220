@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.groupcom {
    height: 100vh;
    // padding: 100px 0px;
    @include flexStyle(center, center, column);

    &__title {
        @include title(24px, 700, 155%);
        margin-bottom: 15px;
        text-align: center;
    }

    &__desc {
        @include title(24px, 400, 28px);
        margin-bottom: 110px;
        text-align: center;
    }

    &__content {
        @include flexStyle(center, space-between, none);
    }

    &__content p {
        @include title(24px, 400, 42px);
        max-width: 550px;
    }

    &__content img {
        width: 565px;
        height: 355px;
    }
}

@media only screen and (max-width: 1160px) {
    .groupcom {
        height: 120vh;

        &__content img {
            width: 100%;
            height: auto;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .groupcom {
        &__content {
            text-align: center;
            @include flexStyle(center, space-between, column);

            p {
                max-width: 100%;
                margin-bottom: 50px;
            }
        }

        &__desc {
            margin-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .groupcom {
        &__content {
            text-align: center;
            @include flexStyle(center, space-between, column);

            p {
                max-width: 100%;
                @include title(18px, 400, 30px);
                margin-bottom: 50px;
            }
        }
    }
}

@media only screen and (max-width: 440px) {
    .groupcom {
        padding-bottom: 0px;
        
        &__desc {
            margin-bottom: 20px;
        }
    }
}

