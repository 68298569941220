@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.businesscom {
    height: 100vh;
    @include flexStyle(center, center, column);
    position: relative;

    &__wrapper {
        position: relative;
    }

    &__asidemenu-wrap {
        position: fixed;
        left: 10%;
        top: 40%;
    }

    &__tell-wrap {
        position: fixed;
        top: 80%;
        right: 10%;
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__bg-title {
        position: absolute;
        top: -20%;
        color: rgba(255, 255, 255, 0.08);
        @include title(111.724px, 700, 155%);
    }

    &__title {
        position: relative;
        @include title(28px, 700, 32px);
        text-align: center;
        text-transform: uppercase;
        margin: 50px 0px 70px 0px;
    }

    &__desc {
        @include title(24px, 400, 28px);
        text-align: center;
        max-width: 1000px;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    &__list {
        @include flexStyle(center, center, none);
        flex-wrap: wrap;
        grid-gap: 0px 100px;

        li {
            @include flexStyle(center, none, column);
            width: 270px;
            height: 160px;
            text-align: center;

            img {
                width: 45px;
                height: 45px;
                margin-bottom: 15px;
            }

            h4 {
                @include title(18px, 700, 21px);
                margin-bottom: 15px;
            }

            p {
                @include title(18px, 400, 21px);
            }
        }

        // li+li {
        //     margin-left: 100px;
        // }
    }
}

@media only screen and (max-width: 1360px) {
    .businesscom {
        &__asidemenu-wrap {
            left: -10%;
        }
    }
}

@media only screen and (max-width: 1360px) {
    .businesscom {
        &__asidemenu-wrap {
            left: -5%;
        }
    }
}

@media only screen and (max-width: 1160px) {
    .businesscom {
        height: 130vh;
        &__asidemenu-wrap {
            display: none;
        }
    
        &__tell-wrap {
            display: none;
        }
    }
}

@media only screen and (max-width: 1060px) {
    .businesscom {
        padding-top: 100px;

        &__list {
            grid-gap: 100px 100px;
        }
    
        &__bg-title {        
            @include title(95px, 700, 135%);
        }
    
    }
}

@media only screen and (max-width: 690px) {
    .businesscom {
        padding-top: 400px;
    }
}

@media only screen and (max-width: 768px) {
    .businesscom {
        &__list {
            grid-gap: 100px 100px;
        }
    
        &__bg-title {
            top: 10%;
            @include title(60px, 700, 125%);
        }

        &__desc {
            @include title(20px, 400, 28px);
            margin-bottom: 80px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .businesscom {
        height: 130vh;

        &__title {
            position: relative;
            @include title(24px, 700, 32px);
            margin: 50px 0px 30px 0px;
        }

        &__desc {
            @include title(18px, 400, 28px);
            margin-bottom: 80px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .businesscom {
        height: 130vh;

        &__title {
            position: relative;
            @include title(24px, 700, 32px);
            margin: 50px 0px 30px 0px;
        }

        &__desc {
            @include title(18px, 400, 28px);
            margin-bottom: 80px;
        }
    }
}


@media only screen and (max-width: 560px) {
    .businesscom {
        &__list {
            grid-gap: 100px 0px;
        }

        &__bg-title {
            top: 20%;
            @include title(45px, 700, 125%);
        }

        &__desc {
            @include title(18px, 400, 28px);
            margin-bottom: 80px;
        }
    }
}

@media only screen and (max-width: 470px) {
    .businesscom {
        &__bg-wrap {
            width: 250px;
        }

        &__desc {
            width: 100%;
        }
    
        &__bg-title {
            top: 30%;
            @include title(45px, 700, 125%);
        }

        &__desc {
            @include title(18px, 400, 28px);
            margin-bottom: 80px;
        }
    }
}