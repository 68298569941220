@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.residenttwo {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__list {
        @include flexStyle(center, none, none);
        margin: 0 auto;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 80px 100px;
        margin-top: 120px;
    }

    &__list>li {
        display: flex;
    }

    &__list>li>li:nth-child(2) {
        margin-left: 120px;
    }

    &__item {
        @include flexStyle(center, none, column);
        padding: 20px;
        width: 335px;
        height: 170px;
        text-align: center;
        background: rgba(108, 99, 255, 0.3);
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }

    &__item img {
        width: 74px;
        height: 74px;
        margin-bottom: 20px;
    }

    &__item p {
        @include title(20px, 400, 23px);
    }
}

@media only screen and (max-width: 900px) {
    .residenttwo {
        &__list {
            grid-gap: 50px 50px;
        }

        &__list>li>li:nth-child(2) {
            margin-left: 50px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .residenttwo {
        height: 120vh;


        &__item {
            width: 250px;
            height: 180px;
        }

        &__item p {
            @include title(16px, 400, 23px);
        }
    }
}

@media only screen and (max-width: 600px) {
    .residenttwo {

        height: 150vh;

        &__list {
            grid-gap: 30px 30px;
        }

        &__list > li {
            flex-direction: column;
        }

        &__list>li>li:nth-child(2) {
            margin-left: 0px;
            margin-top: 30px;
        }

        &__item {
            width: 250px;
            height: 180px;
        }

        &__item p {
            @include title(16px, 400, 23px);
        }
    }
}

@media only screen and (max-width: 368px) {
    .residenttwo {
        &__item {
            width: 230px;
            height: 170px;

            img {
                width: 45px;
                height: 45px;
            }
        }
    }
}