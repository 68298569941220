@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.digitizationcom {
    position: relative;
    height: 100vh;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        @include flexStyle(center, center, column);
        position: relative;
    }

    &__title {
        @include title(28px, 700, 155%);
        text-align: center;
        margin-bottom: 50px;
    }

    &__bg-title {
        position: absolute;
        top: -80%;
        color: rgba(255, 255, 255, 0.08);
        @include title(111.724px, 700, 155.52%);
    }

    &__content {
        @include flexStyle(center, space-between, none);

        p {
            @include title(24px, 400, 155%);
            max-width: 638px;
        }
    }

    &__asidemenu-wrap {
        position: fixed;
        left: 10%;
        top: 40%;
    }

    &__img {
        width: 400px;
        height: 400px;
        animation: fadeInBottomLeft;
        animation-duration: 2s;
    }

    &__tel-wrap {
        position: fixed;
        right: 10%;
        top: 80%;
    }
}

@media only screen and (max-width:1420px) {
    .digitizationcom {
        &__asidemenu-wrap {
            left: 2%;
        }
    }
}

@media only screen and (max-width:1360px) {
    .digitizationcom {
        &__asidemenu-wrap {
            left: 2%;
        }
    }
}

@media only screen and (max-width: 1160px) {
    .digitizationcom {

        &__asidemenu-wrap {
            display: none;
        }

        &__tel-wrap {
            display: none;
        }

        &__title {
            padding-top: 70px;
        }

        &__bg-title {
            top: 7%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 900px) {
    .digitizationcom {
        height: 120vh;

        &__bg-title {
            top: 10%;
            @include title(80px, 700, 155.52%);
        }

        &__content {
            @include flexStyle(center, space-between, column);
            text-align: center;

            p {
                margin-bottom: 50px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .digitizationcom {
        &__bg-title {
            top: 20%;
            @include title(60px, 700, 155%);
        }
    }
}

@media only screen and (max-width: 575px) {
    .digitizationcom {
        &__bg-title {
            top: 28%;
            @include title(45px, 700, 155%);
        }
    }
}

@media only screen and (max-width: 445px) {
    .digitizationcom {
        height: 110vh;

        &__content {
    
            p {
                @include title(18px, 400, 155%);
                max-width: 638px;
                margin-bottom: 40px;
            }
        }

        &__bg-title {
            top: 38%;
            @include title(35px, 700, 90%);
        }

        &__img {
            width: 350px;
            height: 400px;
        }
    }
}

@media only screen and (max-width: 390px) {
    .digitizationcom {
        &__img {
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 340px) {
    .digitizationcom {

        &__title {
            padding-top: 30px;
        }

        &__bg-title {
            top: 20%;
            @include title(30px, 700, 90%);
        }
    }
}