@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.infra {
    height: 100vh;
    width: 1200px;
    margin: 0 auto;
    @include flexStyle(center, center, column);

    &__title {
        text-align: center;
        position: relative;
        @include title(28, 700, 155%);
        margin-bottom: 115px;
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
    }

    &__bg-title {
        position: absolute;
        top: -50%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__img {
        margin-right: 100px;
    }

    &__box {
        position: relative;
        @include flexStyle(center, space-between, none)
    }

    &__num-box {
        position: absolute;
        top: 68%;
        left: 41.5%;
    }

    &__num-box:nth-child(2) {
        position: absolute;
        top: 45px;
        left: 29.5%;
    }

    &__num-box:nth-child(3) {
        position: absolute;
        top: 65%;
        left: 15.6%;
    }

    &__num-box:nth-child(4) {
        position: absolute;
        top: 10%;
        left: 3.9%;
    }

    .sel-num {
        position: absolute;
        top: 10%;
        left: 4.2%;
    }

    &__num-year {
        @include title(30px, 400, 35px);
    }

    &__num-number {
        @include title(40px, 700, 46px);

    }

    &__desc {
        max-width: 518px;
        @include title(24px, 400, 155%);
        margin-bottom: 79px;
    }

    &__link-btn {
        @include button()
    }
}

@media only screen and (max-width: 1195px) {
    .infra {
        width: 100%;

        &__img {
            margin-right: 0px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .infra {
        &__box {
            position: relative;
            @include flexStyle(center, space-between, column-reverse);
            text-align: center;
        }

        &__img {
            margin-top: 50px;
        }

        &__num-box {
            top: 81%;
            left: 29%;
        }

        &__num-box:nth-child(2) {
            top: 51%;
            left: 8.7%;
        }

        &__num-box:nth-child(3) {
            top: 51%;
            left: 56.6%;
        }

        &__num-box:nth-child(4) {
            top: 83%;
            left: 79%;
        }
    }
}

@media only screen and (max-width: 900px) {
    .infra {
        &__bg-title {
            top: -40%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 680px) {
    .infra {
        &__bg-title {
            top: -30%;
            @include title(60px, 700, 155.52%);
        }

        &__title {
            margin-bottom: 50px;
        }

        &__img {
            width: 400px;
            height: 400px;
        }

        &__num-year {
            @include title(20px, 400, 35px);
        }

        &__num-number {
            @include title(25px, 700, 46px);

        }

        &__num-box {
            top: 78%;
            left: 73%;
        }

        &__num-box:nth-child(2) {
            top: 57%;
            left: 17.7%;
        }

        &__num-box:nth-child(3) {
            top: 57%;
            left: 54.6%;
        }

        &__num-box:nth-child(4) {
            top: 76%;
            left: 34%;
        }
    }
}

@media only screen and (max-width: 440px) {
    .infra {
        &__bg-title {
            @include title(45px, 700, 155.52%);
        }

        &__title {
            margin-bottom: 50px;
        }

        &__img {
            width: 100%;
            height: 400px;
        }

        &__desc {
            max-width: 100%;
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__num-year {
            @include title(15px, 400, 20px);

        }

        &__num-number {
            @include title(15px, 700, 20px);

        }

        &__num-box {
            top: 77%;
            left: 74%;
        }

        &__num-box:nth-child(2) {
            top: 52%;
            left: 9.7%;
        }

        &__num-box:nth-child(3) {
            top: 53%;
            left: 57.6%;
        }

        &__num-box:nth-child(4) {
            top: 75%;
            left: 34%;
        }
    }
}

@media only screen and (max-width: 368px) {
    .infra {
        &__num-box {
            display: none;
        }

        &__img {
            display: none;
        }
    }
}