@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.business-section {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__wrap {
        width: 1160px;
        @include flexStyle(center, space-between, none);
        padding-top: 100px;
    }

    &__content {
        h3 {
            @include title(24px, 700, 28px);
            margin-bottom: 70px;
        }

        button {
            @include button();
            background: rgba(255, 255, 255, 0.2);
            border: 1px solid #6C63FF;
            border-radius: 50px;
        }
    }

    &__list {
        width: 500px;
        @include flexStyle(center, none, none);
        flex-wrap: wrap;
        grid-gap: 20px 50px;
        margin-bottom: 50px;


        li {
            width: 200px;
            height: 150px;

            h4 {
                @include title(18px, 700, 21px);
                position: relative;
                margin-bottom: 20px;
            }

            h4::before {
                content: "";
                position: absolute;
                top: -30px;
                width: 70px;
                height: 70px;
                @include title(60px, 700, 70px);
                color: rgba(108, 99, 255, 0.4);
            }

            p {
                @include title(16px, 400, 20px);
            }
        }

        li:nth-child(1) h4::before {
            content: "01";
        }

        li:nth-child(2) h4::before {
            content: "02";
        }

        li:nth-child(3) h4::before {
            content: "03";
        }

        li:nth-child(4) h4::before {
            content: "04";
        }
    }
}

@media only screen and (max-width: 1200px) {
    .business-section {
        &__wrap {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .business-section {
        padding-top: 50px;
        height: 100%;

        &__wrap {
            @include flexStyle(center, space-between, column);

            img {
                margin-bottom: 100px;
            }
        }

        &__content {
            @include flexStyle(center, center, column);

            h3 {
                text-align: center;
            }
        }

        &__list {
            width: 100%;
            @include flexStyle(center, center, none);

            li {
                @include flexStyle(center, center, column);
                text-align: center;
                width: 300px;
                height: 250px;
            }

            li:nth-child(1) h4::before {
                left: -10px;
                top: -40px;
            }

            li:nth-child(2) h4::before {
                top: -40px;
            }

            li:nth-child(3) h4::before {
                left: 40px;
                top: -40px;
            }

            li:nth-child(4) h4::before {
                left: 40px;
                top: -40px;
            }
        }
    }
}

@media only screen and (max-width: 675px) {
    .business-section {
        padding: 200px 0px 0px 0px;
    }
}

@media only screen and (max-width: 505px) {
    .business-section {
        &__wrap {
            img {
                width: 350px;
                height: 350px;
                margin-bottom: 40px;
            }
        }

        &__list {
            li {
                height: 180px;
            }
        }
    }
}

@media only screen and (max-width: 390px) {
    .business-section {
        padding: 200px 0px 150px 0px;
        &__wrap {
            img {
                width: 100%;
                height: 350px;
                object-fit: cover;
                margin-bottom: 40px;
            }
        }

        &__list {
            li {
                width: 100%;
                height: 180px;
            }
        }
    }
}