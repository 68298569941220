.tel {
    position: relative;
    font-family: 'Montserrat';
    padding-left: 30px;
    font-weight: 300;
    font-size: 28.8px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
}

.tel::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 2px;
    width: 30px;
    height: 30px;
    background: url("../../Assets/Icon/call-tell.svg") no-repeat;
}