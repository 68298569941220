@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.startaps {
    // width: 1160px;
    height: 100vh;

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__title {
        text-align: center;
        position: relative;
        @include title(28, 700, 155%);
        margin-bottom: 23px;
    }

    &__bg-title {
        position: absolute;
        top: -150%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__box {
        @include flexStyle(center, space-between, none)
    }

    &__desc {
        max-width: 497px;
        @include title(24px, 400, 155%);
        margin-bottom: 79px;
        // animation: backInLeft;
        // animation-duration: 2s;
    }

    &__link-btn {
        @include button()
    }

    &__img {
        animation: updown 3s linear infinite;
        // animation: backInRight;
        // animation-duration: 2s;
    }

    @keyframes updown {
        0% {
            transform: translateY(-20px);
        }

        50% {
            transform: translateY(20px);
        }

        100% {
            transform: translateY(-20px);
        }
    }
}

@media only screen and (max-width: 900px) {
    .startaps {

        &__box {
            text-align: center;
            @include flexStyle(center, space-between, column-reverse)
        }

        &__desc {
            max-width: 100%;
        }

        &__bg-wrap {
            width: 500px;
            margin: 0 auto;
        }

        &__bg-title {
            top: -100%;
            @include title(90px, 700, 155.52%);
        }

        &__img {
            padding-top: 100px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .startaps {
        &__bg-wrap {
            width: 300px;
        }

        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -70%;
            @include title(60px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 520px) {
    .startaps {
        &__bg-wrap {
            width: 300px;
        }

        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -60%;
            @include title(60px, 700, 155.52%);
        }

        &__img {
            width: 300px;
            height: auto;
        }
    }
}

@media only screen and (max-width: 440px) {
    .startaps {

        height: 100%;

        &__bg-wrap {
            width: 200px;
        }

        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }

        &__bg-title {
            top: -40%;
            @include title(45px, 700, 155.52%);
        }

        &__img {
            width: 300px;
            height: auto;
        }
    }
}