@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.infracom {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__box {
        padding-top: 100px;
        @include flexStyle(center, none, none);
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
    }

    &__bg-title {
        position: absolute;
        top: -80%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111px, 700, 155%);
    }

    &__asidemenu-wrap {
        position: fixed;
        left: 10%;
        top: 40%;
    }

    &__tell-wrap {
        position: fixed;
        right: 10%;
        top: 80%;
    }

    &__title {
        position: relative;
        @include title(28px, 700, 32px);
        margin-bottom: 70px;
    }

    &__content {
        @include flexStyle(center, none, column);
    }

    &__wrapper {
        @include flexStyle(center, none, space-between);
    }

    &__wrap-title {
        @include title(24px, 700, 28px);
        margin-bottom: 20px;
    }

    &__wrap-desc {
        @include title(20px, 400, 155%);
        max-width: 480px;
    }

    &__img {
        margin-left: 150px;
    }
}

@media only screen and (max-width: 1160px) {
    .infracom {
        &__asidemenu-wrap {
            display: none;
        }

        &__tell-wrap {
            display: none;
        }

        &__img {
            margin-left: 100px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .infracom {
padding-bottom: 100px;
        &__img {
            margin-left: 50px;
            width: 100%;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .infracom {
        height: 140vh;

        &__box {
            padding-top: 150px;
        }

        &__wrapper {
            text-align: center;
            @include flexStyle(center, none, column);
        }

        &__img {
            margin-left: 0px;
            margin-top: 100px;
        }

        &__bg-wrap {
            width: 600px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__bg-title {
            position: absolute;
            top: -75%;
            right: -7%;
            @include title(90px, 700, 155%);
        }
    }
}

@media only screen and (max-width: 768px) {
    .infracom {
        height: 140vh;
        padding-bottom: 100px;

        &__bg-wrap {
            width: 430px;
        }

        &__title {
            margin-bottom: 40px;
        }

        &__wrap-desc {
            @include title(18px, 400, 155%);
        }

        &__bg-title {
            top: -40%;
            right: 0%;
            @include title(60px, 700, 155%);
        }
    }
}


@media only screen and (max-width: 470px) {
    .infracom {
        height: 120vh;

        &__bg-wrap {
            width: 250px;
        }

        &__bg-title {
            right: -9%;
            @include title(40px, 700, 155%);
        }
    }
}

@media only screen and (max-width: 368px) {
    .infracom {
        &__wrap-desc {
            @include title(16px, 400, 155%);
        }

        &__img {
            margin-top: 30px;
            height: 300px;
            object-fit: contain;
        }
    }
}