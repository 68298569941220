@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.centercom {
    height: 100vh;
    @include flexStyle(center,center, column);

    &__content {
        width: 1160px;
        @include flexStyle(center, space-between, none);
    }

    &__img {
        width: 440px;
        height: 635px;
    }

    &__title {
        @include title(24px, 700, 28px);
        margin-bottom: 35px;
    }

    &__desc {
        max-width: 421px;
        @include title(20px, 400, 137%);
    }

    &__wraps {
        position: relative;
    }

    &__wrap-title {
        @include title(30px, 400, 35px);
    }

    &__wrap-desc {
        @include title(40px, 700, 46px);
    }

    &__wrap-list {
        @include flexStyle(center, none, column);
        position: absolute;
    }

    &__wrap-list:nth-last-child(1) {
        top: 80%;
        left: 15%;
    }

    &__wrap-list:nth-last-child(2) {
        top: 55%;
        right: 12%;
    }

    &__wrap-list:nth-last-child(3) {
        top: 30%;
        left: 12%;
    }

    &__wrap-list:nth-last-child(4) {
        top: 50px;
        right: 65px;
    }
}

@media only screen and (max-width: 1190px) {
    .centercom {
        &__content {
            width: 100%;
            @include flexStyle(center, space-between, none);
        }
    }
}

@media only screen and (max-width: 900px) {
    .centercom {
        &__content {
            text-align: center;
            @include flexStyle(center, space-between, column-reverse);
        }

        &__desc {
            margin-bottom: 100px;
        }

    }
}

@media only screen and (max-width: 768px) {
    .centercom {
        padding-top: 50px;

        &__desc {
            @include title(18px, 400, 137%);
            margin-bottom: 100px;
        }
    }
}

@media only screen and (max-width: 490px) {
    .centercom {
        height: 100%;

        padding-top: 50px;
        &__desc {
            margin-bottom: 120px;
        }

    }
}

@media only screen and (max-width: 440px) {
    .centercom {
        &__img {
            width: 300px;
            height: 400px;
        }
    
        &__wrap-title {
            @include title(20px, 400, 25px);
        }
    
        &__wrap-desc {
            @include title(30px, 700, 30px);
        }
    
        &__wrap-list {
            @include flexStyle(center, none, column);
            position: absolute;
        }
    
        &__wrap-list:nth-last-child(1) {
            top: 80%;
            left: 18%;
        }
    
        &__wrap-list:nth-last-child(2) {
            top: 55%;
            right: 14%;
        }
    
        &__wrap-list:nth-last-child(3) {
            top: 29%;
            left: 15%;
        }
    
        &__wrap-list:nth-last-child(4) {
            top: 25px;
            right: 50px;
        }
    }
}



