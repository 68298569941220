@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.texnopark {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__wrap-set {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
    }

    &__bg-title {
        position: absolute;
        top: -90px;
        color: rgba(255, 255, 255, 0.04);
        @include title(111.724px, 700, 155.52%);
    }

    &__title {
        position: relative;
        @include title(28px, 700, 32px);
        margin-bottom: 58px;
        text-align: center;
    }

    &__list {
        width: 1000px;
        height: 350px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 130px 100px;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
        margin-bottom: 85px;
    }

    &__item {
        width: 250px;
        text-align: center;
        @include flexStyle(center, none, column);
    }

    &__item-box {
        @include flexStyle(center, none, none);
        margin-bottom: 10px;
    }

    &__item-box {
        span {
            display: inline-block;
            @include title(38px, 700, 155%);
        }
    }

    &__item-icon {
        margin-right: 10px;
    }

    &__item-text {
        @include title(20px, 400, 155%);
    }

    &__link-btn {
        @include button();
        align-self: start;
    }

    .links {
        animation: fadeInLeft;
        animation-duration: 3s;
    }
}

@media only screen and (max-width: 1060px) {
    .texnopark {
        height: 130vh;

        &__bg-title {
            // left: -150%;
        }

        &__wrapper {
            @include flexStyle(center, center, column);
        }

        &__list {
            width: 100%;
            height: 700px;
        }

        &__link-btn {
            @include button();
            align-self: flex-end;
        }
    }
}

@media only screen and (max-width: 900px) {
    .texnopark {

        &__bg-title {
            top: -70px;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 768px) {
    .texnopark {
        &__bg-title {
            top: -40px;
            @include title(60px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 640px) {
    .texnopark {
        padding-bottom: 150px;
        height: 100%;

        &__list {
            width: 100%;
            height: 1200px;
            grid-gap: 100px 0px;
        }

        &__item {
            height: 100px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .texnopark {

        &__bg-title {
            top: -35px;
            @include title(45px, 700, 155.52%);
        }
    }
}