@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.directions {
    height: 100vh;


    &__title {
        @include title(28px, 700, 155%);
        text-align: center;
        margin-bottom: 70px;
    }

    ul {
        width: 1000px;
        @include flexStyle(center, center, none);
        margin: 0 auto;
        flex-wrap: wrap;
        grid-gap: 150px 100px;
        margin-bottom: 150px;

        li {
            width: 250px;
            height: 150px;
            @include flexStyle(center, none, column);

            img {
                margin-bottom: 20px;
            }

            p {
                @include title(28px, 500px, 35px);
                font-family: 'Montserrat';
                text-align: center;
            }

        }
    }
}

@media only screen and (max-width: 1060px) {
    .directions {
    height: 100%;
        
        ul {
            width: 600px;
            grid-gap: 100px 100px;
        }
    }
}

@media only screen and (max-width: 678px) {
    .directions {
        ul {
            width: 400px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .directions {

        ul {
            width: 200px;
            grid-gap: 50px 0px;
            margin-bottom: 50px;

            li {
                width: 200px;
                height: 150px;
                @include flexStyle(center, none, column);
    
                img {
                    width: 70px;
                    height: 60px;
                    margin-bottom: 20px;
                }
    
                p {
                    @include title(18px, 500px, 35px);
                }
            }
        }
    }
}