@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.eventsprof {
    height: 100%;
    padding-top: 200px;
    @include flexStyle(center, center, column);

    &__asidemenu-wrap {
        position: fixed;
        top: 40%;
        left: 10%;
    }

    &__bg-wrap {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;
        padding-bottom: 100px;
    }

    &__bg-title {
        position: absolute;
        @include title(110px, 700, 155%);
        color: rgba(255, 255, 255, 0.04);
    }

    &__title {
        @include title(28px, 700, 32px);
    }

    &__list {
        margin-top: 150px;
    }

    &__item {
        @include flexStyle(center, space-between, none);

        img {
            width: 600px;
            height: 330px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item:nth-child(odd) {
        @include flexStyle(center, space-between, none);
    }

    &__item:nth-child(even) {
        @include flexStyle(center, space-between, row-reverse);

        .eventsprof__box {
            margin-left: 0px;
            margin-right: 30px;
        }
    }

    &__item~&__item {
        margin-top: 100px;
    }

    &__box {
        margin-left: 30px;
    }

    &__item-title {
        @include title(22px, 700, 25px);
        margin-bottom: 20px;
    }

    &__item-desc {
        @include title(16px, 400, 19px);
        max-width: 450px;
    }
}

@media(max-width: 1160px) {
    .eventsprof {
        &__asidemenu-wrap {
            display: none;
        }
    }
}

@media(max-width: 900px) {
    .eventsprof {
        &__bg-title {
            @include title(90px, 700, 155%);
        }
    }
}


@media(max-width: 768px) {
    .eventsprof {
        &__bg-title {
            @include title(60px, 700, 155%);
        }

        &__item {
            img {
                width: 100%;
                height: auto;
                margin-bottom: 40px;
            }
        }

        &__item:nth-child(odd) {
            @include flexStyle(center, space-between, column);
            text-align: center;
            .eventsprof__box {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    
        &__item:nth-child(even) {
            @include flexStyle(center, space-between, column);
            text-align: center;
    
            .eventsprof__box {
                margin-left: 0px;
                margin-right: 0px;
            }
        }

        &__item-desc {
            max-width: 100%;
        }
    }
}

@media(max-width: 368px) {
    .eventsprof {
        &__bg-title {
            @include title(45px, 700, 155%);
        }
    }
}