@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.digitixation {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        @include flexStyle(center, center, column);
        margin: 0 auto;
        position: relative;
    }

    &__bg-title {
        position: absolute;
        top: -50%;
        color: rgba(255, 255, 255, 0.04);
        @include title(111px, 700, 155.52%);
    }

    &__title {
        text-align: center;
        position: relative;
        @include title(28, 700, 155%);
        margin-bottom: 115px;
    }

    &__box {
        width: 1160px;
        @include flexStyle(center, space-between, none)
    }

    &__desc {
        max-width: 534px;
        @include title(24px, 400, 155%);
        margin-bottom: 79px;
    }

    &__link-btn {
        @include button()
    }

    &__img {
        animation: updown 3s linear infinite;
    }

    @keyframes updown {
        0% {
            transform: translateY(-20px);
        }

        50% {
            transform: translateY(20px);
        }

        100% {
            transform: translateY(-20px);
        }
    }
}

@media only screen and (max-width: 1200px) {
    .digitixation {
        &__box {
            width: 100%;
        }

        &__img {
            margin-right: 100px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .digitixation {

        &__img {
            margin-right: 50px;
        }

        &__bg-title {
            top: -50%;
            @include title(90px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 900px) {
    .digitixation {

        &__box {
            text-align: center;
            @include flexStyle(center, space-between, column-reverse)
        }

        &__title {
            margin-bottom: 50px;
        }
    
        &__desc {
            max-width: 100%;
        }

        &__img {
            margin-top: 50px;
            margin-right: 0px;
        }

        &__bg-title {
            top: -60%;
            @include title(60px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 768px) {
    .digitixation {

        &__bg-title {
            top: -50%;
        }

        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 580px) {
    .digitixation {

        &__bg-title {
            top: -40%;
            @include title(45px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 470px) {
    .digitixation {
        &__bg-title {
            top: -30%;
            @include title(40px, 700, 155.52%);
        }

        &__img {
            width: 100%;
            height: auto;
        }
    }
}

@media only screen and (max-width: 440px) {
    .digitixation {
        &__title {
            margin-bottom: 20px;
        }
    }
}

@media only screen and (max-width: 368px) {
    .digitixation {
        &__bg-title {
            top: -30%;
            @include title(35px, 700, 155.52%);
        }
    }
}

@media only screen and (max-width: 340px) {
    .digitixation {

        &__bg-title {
            top: -20%;
            @include title(30px, 700, 155.52%);
        }
    }
}

