@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.coworking {
    height: 100%;
    margin: 0 auto;

    &__title {
        @include title(24px, 700, 28px);
        margin-bottom: 25px;
        text-align: center;
    }

    &__desc {
        @include title(24px, 400, 28px);
        margin-bottom: 100px;
        text-align: center;
    }

    &__wrapper {
        height: 1000px;
        margin: 0 auto;
    }

    &__wrap-one {
        @include flexStyle(center, space-between, none);
        margin-bottom: 100px;
    }

    &__wrap-one p {
        @include title(24px, 400, 28px);
        max-width: 530px;
    }

    &__box-one {
        position: relative;
        width: 560px;
        height: 325px;
    }

    &__box-one img {
        position: absolute;
    }

    &__box-one img:nth-child(1) {
        left: 0px;
    }

    &__box-one img:nth-child(2) {
        left: 210px;
    }

    &__box-one img:nth-child(3) {
        left: 434px;
        top: -22px;
    }

    &__box-one img:nth-child(4) {
        left: 212px;
        top: 126px;
    }

    &__box-one img:nth-child(5) {
        left: 363px;
        top: 126px;
    }

    &__box-one img:nth-child(6) {
        left: 364px;
        top: 225px;
    }

    // WPAR-TWO

    &__wrap-two {
        @include flexStyle(center, space-between, none);
    }

    &__wrap-two p {
        max-width: 440px;
        @include title(24px, 400, 28px);
    }

    &__box-two {
        position: relative;
        width: 570px;
        height: 325px;
    }

    &__box-two img {
        position: absolute;
    }

    &__box-two img:nth-child(2) {
        top: 126px;
        left: 40px;
    }

    &__box-two img:nth-child(3) {
        top: -20px;
        left: 225px;
    }

    &__box-two img:nth-child(4) {
        top: 127px;
        left: 193px;
    }

    &__box-two img:nth-child(5) {
        top: 226px;
        left: 192px;
    }

    &__box-two img:nth-child(6) {
        top: 39px;
        left: 366px;
    }
}

@media only screen and (max-width: 1130px) {
    .coworking {

        &__wrapper {
            height: 100%;
            margin: 0 auto;
        }

        &__desc {
            margin-bottom: 50px;
        }

        &__wrap-one {
            @include flexStyle(center, space-between, column);
            margin-bottom: 100px;
            text-align: center;
        }

        &__wrap-one p {
            @include title(24px, 400, 35px);
            max-width: 100%;
            margin-bottom: 100px;
        }

        &__wrap-two {
            @include flexStyle(center, space-between, column-reverse);
            text-align: center;
        }

        &__wrap-two p {
            @include title(24px, 400, 35px);
            margin-bottom: 100px;
            max-width: 100%;
        }

        &__box-two img:nth-child(1) {
            top: 0%;
            left: 0px;
        }
    }
}

@media only screen and (max-width: 610px) {
    .coworking {
        margin-bottom: 200px;

        &__wrap-one {
            p {
                padding-bottom: 200px;
            }
        }

        &__box-one {
            position: relative;
            width: 100%;
            height: 100%;
            grid-gap: 100px;
        }

        &__box-one img {
            position: relative;
            object-fit: cover;
            border-radius: 15px;
        }

        &__box-one img:nth-child(odd) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
        }

        &__box-one img:nth-child(even) {
            left: 10px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
        }


        &__box-one img:nth-child(1) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
            top: -200px;
        }

        &__box-one img:nth-child(2) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
            top: -150px;
        }

        &__box-one img:nth-child(3) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
            top: -100px;
        }

        &__box-one img:nth-child(4) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
            top: -50px;
        }

        &__box-one img:nth-child(5) {
            left: 0px;
            object-fit: cover;
            border-radius: 15px;
            width: 100%;
            height: 300px;
            top: 0px;
        }

        &__box-one img:nth-child(6) {
            left: 0px;
            width: 100%;
            height: 300px;
            top: 50px;
        }

        // WPAR-TWO

        &__wrap-two {
            @include flexStyle(center, space-between, none);
            padding-top: 150px;
        }

        &__wrap-two p {
            @include title(24px, 400, 28px);
        }

        &__box-two {
            position: relative;
            width: 100%;
            height: 100%;
        }

        &__box-two img {
            width: 100%;
            height: 300px;
            position: relative;
        }

        &__box-two img:nth-child(1) {
            top: 50px;
            left: 0px;
        }

        &__box-two img:nth-child(2) {
            top: 100px;
            left: 0px;
        }

        &__box-two img:nth-child(3) {
            top: 150px;
            left: 0px;
        }

        &__box-two img:nth-child(4) {
            top: 200px;
            left: 0px;
        }

        &__box-two img:nth-child(5) {
            top: 250px;
            left: 0px;
        }

        &__box-two img:nth-child(6) {
            top: 300px;
            left: 0px;
        }
    }
}