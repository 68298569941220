@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.companiescom {
    height: 100%;

    &__title {
        @include title(24px, 700, 28px);
        margin-bottom: 30px;
        text-align: center;
    }

    &__desc {
        max-width: 740px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 100px;
    }

    &__wrapper {
        @include flexStyle(center, space-between, none);
        width: 1160px;
        margin-bottom: 200px;
    }

    &__wrap-list {
        width: 670px;
        @include flexStyle(center, none, none);
        width: 650px;
        display: flex;
        flex-wrap: wrap;
        grid-gap: 50px 50px;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }

    &__wrap-two {
        @include flexStyle(center, space-between, none);
        height: 300px;
    }

    &__wrap2-list {
        width: 700px;
        @include flexStyle(center, none, none);
        display: flex;
        flex-wrap: wrap;
        grid-gap: 50px 50px;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }

    &__wrap-three {
        @include flexStyle(center, space-between, none);
        height: 400px;
    }

    &__wrap3-list {
        // width: 800px;
        @include flexStyle(center, none, none);
        display: flex;
        flex-wrap: wrap;
        grid-gap: 50px;
        grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
    }
}

@media only screen and (max-width: 1170px) {
    .companiescom {
        height: 100%;
        padding-top: 500px;

        &__wrapper {
            @include flexStyle(center, space-between, none);
            width: 100%;
            margin-bottom: 200px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .companiescom {
        height: 100%;
        padding-top: 400px;

        &__wrapper-title {
            margin-bottom: 70px;
        }

        &__wrapper {
            @include flexStyle(center, center, column);
            margin-bottom: 200px;
        }

        &__wrap-list {
            @include flexStyle(center, center, column);
            margin-bottom: 100px;
        }

        &__wrap-two {
            @include flexStyle(center, center, column-reverse);
            margin-bottom: 150px;
        }

        &__wrap2-list{
            @include flexStyle(center, center, column);
            margin-bottom: 150px;
        }

        &__wrap-three {
            @include flexStyle(center, center, column);
            margin-top: 50px;
        } 

        &__wrap3-list{
            @include flexStyle(center, center, column);
        }
    }
}

@media only screen and (max-width: 805px) {
    .companiescom {
        text-align: center;
        padding-top: 50px;

        &__wrap-list {
            width: 100%;
        }
        &__wrap2-list {
            width: 100%;
        }
        &__wrar3-list {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 640px) {
    .companiescom {
        padding-top: 0px;

        li {
            img {
                width: 100%;
            height: 30px;
            object-fit: cover;
            }
        }
    }
}