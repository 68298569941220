@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.nav {
    position: relative;
    @include flexStyle(center, center, none);

    &__list {
        position: relative;
        @include flexStyle(center, none, none);
        margin-right: 100px;

    }

    &__item+&__item {
        margin-left: 30px;
    }

    &__link {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $white;
        transition: all 0.5s ease;        
    }

    &__item:hover {
        animation: headShake;
        animation-duration: 0.8s;
    }

    &__select {
        padding: 7px 11px;
        border: none;
        color: #fff;
        background-color: transparent;
        border: 0.93617px solid rgba(255, 255, 255, 0.5);
        border-radius: 23.4043px;

        option {
            background-color: #000;
        }
    }

    &__clos-burber {
        display: none;
    }
}

.nav__menu {
    display: none;
}

@media only screen and (max-width: 1200px) {
    .nav {
        &__list {
            margin-right: 50px;
        }
    }
}


@media only screen and (max-width: 1140px) {
    .nav {
        &__list {
            margin-right: 10px;
        }

        &__item+&__item {
            margin-left: 20px;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .nav {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100vw;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background: rgba(0, 0, 0, 0.7);
        backdrop-filter: blur(20px);
        transition: 0.9s;
        transform: translateX(150vh);


        &__list {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 0px;
        }

        &__item+&__item {
            margin-left: 0px;
            margin-top: 50px;
        }

        &__clos-burber {
            display: flex;
            position: absolute;
            top: 30px;
            right: 30px;
            border: none;
            color: #fff;
            font-size: 26px;
            padding: 0;
            background-color: transparent;
        }

        &__select {
            display: none;
        }
    }

    #hamburger-icon {
        display: flex;
        width: 35px;
        height: 35px;
        padding: 10px;
        display: block;
        background-color: transparent;
        border: none;
        padding: 0px;
        // border: 1px solid #cacaca;
    }

    #hamburger-icon .line {
        width: 35px;
        background: #fff;
        margin: 0px 0px 8px 0px;
        height: 3px;
        display: block;
        border-radius: 10px;
        transition: all 800ms cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    .line-1 {
        margin-bottom: 0;
    }

    #hamburger-icon:hover .line-1 {
        width: 30px;
    }

    #hamburger-icon:hover .line-2 {
        width: 40px;
    }

    #hamburger-icon:hover .line-3 {
        width: 20px;
    }

    .res_nav {
        transform: none;

        .nav__item {
            animation: backInRight;
            animation-duration: 2s;
        }

        .nav__item:nth-child(2) {
            animation: backInLeft;
            animation-duration: 2s;
        }

        .nav__item:nth-child(3) {
            animation: backInRight;
            animation-duration: 2s;
        }

        .nav__item:nth-child(4) {
            animation: backInLeft;
            animation-duration: 2s;
        }

        .nav__item:nth-child(5) {
            animation: backInRight;
            animation-duration: 2s;
        }

        .nav__item:nth-child(6) {
            animation: backInLeft;
            animation-duration: 2s;
        }
    }

    .nav__select {
        display: none;
    }
}
