@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.startapcom {
    position: relative;
    height: 100vh;
    @include flexStyle(center, center, column);

    &__header-box {
        position: relative;
        @include flexStyle(center, center, column);
        margin: 0 auto;

        div {
            position: absolute;
            top: -20%;
            color: rgba(255, 255, 255, 0.08);
            @include title(111.724px, 700, 155.52%);
        }
    }

    &__title {
        position: relative;
        @include title(28px, 700, 155%);
        text-align: center;
        margin-top: 50px;
        margin-bottom: 30px;
        animation: zoomIn;
        animation-duration: 2s;
    }

    &__tel-wrap {
        position: fixed;
        top: 80%;
        right: 10%;
    }

    &__content {
        @include flexStyle(center, space-between, none);

        img {
            animation: fadeInBottomLeft;
            animation-duration: 2s;
        }
    }

    &__asidemenu-wrap {
        position: fixed;
        left: 10%;
        top: 40%;
    }

    &__wrap {
        h3 {
            @include title(24px, 700, 155%);
            margin-bottom: 20px;
            animation: fadeInUp;
            animation-duration: 2s;
        }

        p {
            @include title(24px, 400, 155%);
            max-width: 638px;
            animation: fadeInUp;
            animation-duration: 3s;
        }
    }
}

@media only screen and (max-width: 1380px) {
    .startapcom {
        &__asidemenu-wrap {
            left: -8%;
        }
    }
}

@media only screen and (max-width: 1260px) {
    .startapcom {
        &__content {
            width: 1000px;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 1160px) {
    .startapcom {
        &__asidemenu-wrap {
            display: none;
        }

        &__tel-wrap {
            display: none;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .startapcom {
        &__content {
            width: 850px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .startapcom {
        padding-top: 100px;
        height: 150vh;
        text-align: center;

        &__header-box {
            div {
                position: absolute;
                top: -30%;
                @include title(90px, 700, 155.52%);
            }
        }

        &__title {
            @include title(28px, 700, 155%);
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &__content {
            width: 100%;
            height: 900px;
            @include flexStyle(center, start, column);

            p {
                margin-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .startapcom {
        &__header-box {

            div {
                top: 0%;
                @include title(60px, 700, 155%);
            }
        }

        &__content {
            p {
                margin-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 560px) {
    .startapcom {

        &__title {
            @include title(28px, 700, 155%);
            margin-top: 30px;
            margin-bottom: 30px;
        }

        &__content {
            p {
                @include title(20px, 400, 155%);
                margin-bottom: 40px;
            }

            img {
                width: 300px;
                height: 300px;
            }
        }
    }
}

@media only screen and (max-width: 430px) {
    .startapcom {
        &__header-box {

            div {
                top: 0%;
                @include title(45px, 700, 155.52%);
            }
        }

    }
}

@media only screen and (max-width: 368px) {
    .startapcom {

        &__title {
            @include title(28px, 700, 155%);
            margin-top: 30px;
            margin-bottom: 10px;
        }

        &__content {

            h3 {
                @include title(20px, 700, 155%);
                margin-bottom: 20px;
            }

            p {
                @include title(20px, 400, 155%);
                margin-bottom: 40px;
            }

            img {
                width: 250px;
                height: 250px;
            }
        }
    }
}