@import './Colors.scss';

@mixin flexStyle($ai, $jc, $fd) {
    display: flex;
    align-items: $ai;
    justify-content: $jc;
    flex-direction: $fd;
}

@mixin title($fs, $fw, $lh) {
    margin: 0;
    font-weight: $fw;
    font-size: $fs;
    line-height: $lh;
}

@mixin button() {
    padding: 13px 30px;
    font-family: 'Play';
    font-weight: 400;
    font-size: 14.9787px;
    line-height: 17px;
    text-transform: capitalize;
    color: $white;
    border: none;
    border: 0.93617px solid $white;
    border-radius: 23.4043px;
    background-color: transparent;
    cursor: pointer;
}

@mixin formInput() {
    padding: 10px 0px;
    width: 100%;
    font-family: 'Play';
    border: none;
    border-bottom: 1.5px solid $gray80;
    background-color: transparent;
    outline: none;
}

@mixin text16() {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: $white;
    text-transform: uppercase;
}