@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.texnoparkcom {
    height: 100vh;
    @include flexStyle(center, center, column);

    &__content {
        width: 1160px;
        @include flexStyle(center, space-between, none);
        margin: 0 auto;
    }

    &__img {
        margin-top: 150px;
        width: 635px;
        height: 345px;
    }

    &__title {
        @include title(24px, 700, 28px);
        margin-bottom: 35px;
    }

    &__desc {
        max-width: 421px;
        @include title(20px, 400, 137%);
    }
}

@media only screen and (max-width: 1180px) {
    .texnoparkcom {
        &__content {
            width: 100%;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .texnoparkcom {
        &__img {
            margin-top: 150px;
            width: 500px;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 900px) {
    .texnoparkcom {
        padding-top: 200px;
        padding-bottom: 150px;
        text-align: center;

        &__content {
            @include flexStyle(center, space-between, column);
        }

        &__desc {
            max-width: 700px;
        }

        &__img {
            margin-top: 100px;
            width: 500px;
            height: 300px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .texnoparkcom {
        height: 100%;
        &__desc {
            @include title(18px, 400, 137%);
        }
    }
}

@media only screen and (max-width:505px) {
    .texnoparkcom {
        &__img {
            width: 100%;
            height: auto;
        }
    }
}