@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.header {
    position: fixed;
    right: 0px;
    left: 0px;
    padding: 50px 0px;
    z-index: 1000;
    transition: all 0.5s ease;

    &__wrapper {
        @include flexStyle(center, space-between, none);
    }

    &__logo-link:hover {
        animation: jello;
        animation-duration: 4s;
    }

    .burger-container {
        width: 45px;
        height: 45px;
        cursor: pointer;

        .burger-container.option {
            span {
                background-color: #dfdffd;
                transition: width 0.5s, background-color 0.5s;

                &:first-child {
                    width: 50%;
                    margin-left: auto;
                }

                &:last-child {
                    width: 50%;
                }
            }

            .burger-container.option:hover {
                span {
                    background-color: #9bd3ff;

                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.header.activ {
    padding: 25px 0px;
    backdrop-filter: blur(20px);
    // background: rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
}


@media only screen and (max-width: 768px) {
    .header {
        padding: 35px 0px;

        &__logo-img {
            width: 150px;
            height: 50px;
        }
    }

    .header.activ {
        padding: 25px 0px;
        border-radius: 0px 0px 15px 15px;
    }
}

@media only screen and (max-width: 368px) {
    .header {
        padding: 20px 0px;

        &__logo-img {
            width: 140px;
            height: 60px;
        }
    }

    .header.activ {
        padding: 15px 0px;
        border-radius: 0px 0px 10px 10px;
    }
    
}