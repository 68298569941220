@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.texnocom {
    position: relative;
    height: 100vh;
    margin-bottom: 100px;
    @include flexStyle(center, center, column);
    &__box {
        position: relative;
        @include flexStyle(center, none, none);
    }

    &__asidemenu {
        position: fixed;
        top: 40%;
        left: 10%;
    }

    &__video {
        position: absolute;
        width: 100vw;
        height: 100%;
        top: -150px;
        left: -33.5%;
        object-fit: cover;
        z-index: -1;
    }

    &__header {
        @include title(28px, 700, 32px);
        // padding-top: 200px;
        margin-bottom: 25px;
        text-align: center;
    }

    &__title {
        @include title(73px, 700, 84px);
        margin-bottom: 27px;
        text-align: center;
    }

    &__subtitle {
        @include title(58px, 400, 67px);
        text-align: center;
    }

    &__tel-wrap {
        position: fixed;
        top: 80%;
        right: 10%;
    }
}

@media only screen and (max-width: 1360px) {
    .texnocom {
        &__asidemenu {
            position: absolute;
            top: 70%;
            left: -10%;
        }
    }
} 

@media only screen and (max-width: 1260px) {
    .texnocom {
        &__asidemenu {
            position: absolute;
            left: -5%;
        }
    }
} 

@media only screen and (max-width: 1160px) {
    .texnocom {
        &__asidemenu {
            display: none;
        }

        &__tel-wrap {
            display: none;
        }

        &__header {
            @include title(24px, 700, 24px);
            margin-bottom: 20px;
        }
    
        &__title {
            @include title(60px, 700, 84px);
            margin-bottom: 20px;
        }
    
        &__subtitle {
            @include title(30px, 400, 67px);
        }
    }
} 

@media only screen and (max-width: 768px) {
    .texnocom {

        &__header {
            @include title(24px, 700, 24px);
            margin-top: 80px;
            margin-bottom: 30px;
        }
    
        &__title {
            @include title(45px, 700, 45px);
            margin-bottom: 30px;
        }
    
        &__subtitle {
            @include title(28px, 400, 30px);
        }
    }
} 

@media only screen and (max-width: 368px) {
    .texnocom {
        &__asidemenu {
            display: none;
        }

        &__header {
            @include title(24px, 700, 24px);
            margin-top: 80px;
            margin-bottom: 25px;
        }
    
        &__title {
            @include title(40px, 700, 45px);
            margin-bottom: 25px;
        }
    
        &__subtitle {
            @include title(24px, 400, 30px);
        }
    }
} 