@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.ourmission {
    height: 100%;
    @include flexStyle(center, center, column);

    &__title {
        @include title(24px, 700, 150%);
        margin-bottom: 40px;
        text-align: center;
    }

    &__desc {
        @include title(22px, 400, 33px);
        max-width: 966px;
        margin: 0 auto;
        margin-bottom: 50px;
        text-align: center;
        
    }

    &__subtitle {
        @include title(24px, 700, 28px);
        text-align: center;
        margin-bottom: 125px;
    }

    &__wrapper {
        height: 1000px;
        margin: 0 auto;
    }

    &__list {
        width: 1000px;
        @include flexStyle(center, none, none);
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        grid-gap: 100px 50px;
        margin: 0 auto;
    }

    &__list li:nth-child(1) {
        display: flex;
    }

    &__list li:nth-child(1) &__item:nth-child(2) {
        margin-left: 55px;
    }

    &__item {
        @include flexStyle(center, none, column);
        position: relative;
        padding: 65px 25px 25px 25px;
        width: 300px;
        height: 280px;
        text-align: center;
        background: rgba(108, 99, 255, 0.2);
        backdrop-filter: blur(10px);
        border-radius: 15px;
    }

    &__item-img {
        position: absolute;
        top: -20%;

    }

    &__item-title {
        @include title(16px, 700, 19px);
        margin-bottom: 25px;

    }

    &__item-desc {
        @include title(15px, 400, 25px);
    }

    &__item-img {
        animation: updown 7s linear infinite;
    }

    @keyframes updown {
        0% {
            transform: translateY(-10px);
        }

        50% {
            transform: translateY(10px);
        }

        100% {
            transform: translateY(-10px);
        }
    }
}


@media only screen and (max-width: 1080px) {
    .ourmission {
height: 100%;
        &__wrapper {
            height: 1400px;
        }

        &__list {
            width: 800px;
            grid-gap: 70px 50px;
        }
    }
}

@media only screen and (max-width: 800px) {
    .ourmission {
        &__list {
            width: 700px;
            grid-gap: 70px 50px;
        }
    }
}

@media only screen and (max-width: 740px) {
    .ourmission {

        &__wrapper {
            height: 2000px;
        }

        &__list {
            width: 400px;
            flex-wrap: wrap;
            grid-gap: 100px 50px;
        }

        &__list li:nth-child(1) {
            flex-direction: column;
        }
    
        &__list li:nth-child(1) &__item:nth-child(2) {
            margin-left: 0px;
            margin-top: 100px;
        }
    }
}

@media only screen and (max-width: 450px) {
    .ourmission {

        &__wrapper {
            height: 2000px;
        }

        &__list {
            width: 300px;
        }
    }
}

@media only screen and (max-width: 368px) {
    .ourmission {
        &__title {
            @include title(22px, 700, 150%);
            margin-top: 30px;
            margin-bottom: 40px;
            text-align: center;
        }
    
        &__desc {
            @include title(18px, 400, 33px);
            max-width: 966px;
            margin: 0 auto;
            margin-bottom: 50px;
            text-align: center;
            
        }
    
        &__subtitle {
            @include title(24px, 700, 28px);
            text-align: center;
            margin-bottom: 125px;
        }

        &__wrapper {
            height: 2100px;
        }

        &__list {
            width: 250px;
        }

        &__item {
            padding: 65px 20px 20px 20px;
            width: 250px;
            height: 290px;
        }
    

        &__item-title {
            @include title(16px, 700, 19px);
            margin-bottom: 25px;
    
        }
    
        &__item-desc {
            @include title(14px, 400, 23px);
        }
    }
}  

