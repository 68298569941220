@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.incubationcom {
    // height: 100vh;

    &__title {
        @include title(28px, 700, 155%);
        margin-bottom: 100px;
        text-align: center;
    }

    &__wrap {
        width: 1160px;
        height: 1000px;
        margin: 0 auto;
    }

    &__list-one {
        @include flexStyle(center, center, none);
        flex-wrap: wrap;
        margin-bottom: 100px;

        li {
            @include flexStyle(center, none, column);
            width: 200px;
            height: 200px;
            text-align: center;

            img {
                width: 140px;
                height: 140px;
                margin-bottom: 15px;
            }

            p {
                @include title(16px, 700, 155%)
            }
        }

        li+li {
            margin-left: 50px;
        }
    }

    &__box {
        @include flexStyle(center, space-between, none);
        margin-bottom: 70px;

        h3 {
            @include title(24px, 700, 155%);
            margin-bottom: 35px;
        }

        p {
            @include title(24px, 400, 155%);
            max-width: 685px;
        }

        img {
            animation: updown 3s linear infinite;
        }

        @keyframes updown {
            0% {
                transform: translateY(-20px);
            }

            50% {
                transform: translateY(20px);
            }

            100% {
                transform: translateY(-20px);
            }
        }
    }

    &__list-two {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-auto-rows: 55px;
    }

    &__item-two {
        @include title(14px, 700, 155%);
        position: relative;
        padding-left: 45px;
    }

    &__item-two::before {
        content: "";
        position: absolute;
        top: -5px;
        left: 0px;
        width: 34px;
        height: 34px;
        background: url("../../../Assets/Img/incubation-icon.svg") no-repeat;
        background-size: contain;
    }
}

@media only screen and (max-width: 1160px) {
    .incubationcom {
        &__wrap {
            width: 1000px;
            height: 100%;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 1030px) {
    .incubationcom {
        height: 100%;



        &__wrap {
            width: 100%;
            height: 100%;
        }

        &__list-one {
            width: 500px;
            margin: 0 auto;
            margin-bottom: 150px;
            grid-gap: 40px 80px;


            li {
                p {
                    @include title(16px, 700, 155%)
                }
            }

            li+li {
                margin-left: 0px;
            }
        }

        &__box {
            @include flexStyle(center, none, column);
            text-align: center;

            h3 {
                margin-bottom: 35px;
            }

            p {
                max-width: 685px;
                margin-bottom: 40px;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .incubationcom {
        &__list-two {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media only screen and (max-width: 560px) {
    .incubationcom {

        &__wrap {
            height: 2300px;
        }

        &__title {
            @include title(24px, 700, 155%);
            margin-bottom: 80px;
            text-align: center;
        }

        &__list-one {
            width: 250px;
            grid-gap: 60px 0px;
            margin-bottom: 100px;
        }

        &__box {
            @include flexStyle(center, none, column);
            text-align: center;

            h3 {
                @include title(24px, 700, 155%);
                margin-bottom: 35px;
            }

            p {
                @include title(20px, 400, 155%);
                max-width: 685px;
                margin-bottom: 40px;
            }

            img {
                width: 350px;
                height: 200px;
            }
        }

        &__list-two {
            grid-template-columns: repeat(1, 1fr);
            margin: 0 auto;
            width: 300px;
        }
    }
}

@media only screen and (max-width: 420px) {
    .incubationcom {
        &__wrap {
            width: 100%;
            height: 2400px;
        }
    }
}

@media only screen and (max-width: 368px) {
    .incubationcom {

        &__wrap {
            width: 100%;
            height: 2300px;
        }

        &__box {
            p {
                @include title(16px, 400, 155%);
                margin-bottom: 40px;
            }

            img {
                width: 250px;
                height: 150px;
            }
        }

        &__item-two {
            @include title(12px, 700, 155%);
        }
    }
}