@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.education {
    // width: 1160px;
    height: 100vh;
    @include flexStyle(center, center, column);

    &__bg-wrap {
        @include flexStyle(center, center, column);
        margin: 0 auto;
        position: relative;
    }

    &__title {
        position: relative;
        text-align: center;
        @include title(28px, 700, 155%);
        margin-bottom: 58px;
    }

    &__bg-title {
        position: absolute;
        top: -70%;
        color: rgba(255, 255, 255, 0.04);
        @include title(100px, 700, 155%)
    }

    &__box {
        @include flexStyle(center, space-between, none);
    }

    &__desc {
        max-width: 676px;
        @include title(24px, 400, 155%);
        margin-bottom: 70px;
    }

    &__link-btn {
        @include button();
    }

    &__img {
        animation: updown 3s linear infinite;
    }

    @keyframes updown {
        0% {
            transform: translateX()(-20px);
        }

        50% {
            transform: translateX()(20px);
        }

        100% {
            transform: translateX(-20px);
        }
    }
}

@media only screen and (max-width: 900px) {
    .education {
        &__box {
            @include flexStyle(center, space-between, column);
            text-align: center;
        }

        &__bg-title {
            top: -70px;
            @include title(90px, 700, 155%)
        }

        &__img {
            margin-top: 100px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .education {

        &__title {
            margin-bottom: 20px;
        }

        &__bg-title {
            top: -40px;
            @include title(60px, 700, 155%)
        }

        &__desc {
            max-width: 100%;
            @include title(18px, 400, 155%);
            margin-bottom: 50px;
        }
    }
}

@media only screen and (max-width: 440px) {
    .education {

        &__bg-title {
            top: -30px;
            @include title(45px, 700, 155%)
        }

        &__img {
            width: auto;
            height: 300px;
        }
    }
}