@font-face {
    font-family: 'Montserrat';
    src: url('./Assets/Fonts/Montserrat-Medium.woff2') format('woff2'),
        url('./Assets/Fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Assets/Fonts/Montserrat-Light.woff2') format('woff2'),
        url('./Assets/Fonts/Montserrat-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Assets/Fonts/Montserrat-Bold.woff2') format('woff2'),
        url('./Assets/Fonts/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./Assets/Fonts/Montserrat-SemiBold.woff2') format('woff2'),
        url('./Assets/Fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Press Start 2P';
    src: url('./Assets/Fonts/PressStart2P-Regular.woff2') format('woff2'),
        url('./Assets/Fonts/PressStart2P-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Play';
    src: url('./Assets/Fonts/Play-Regular.woff2') format('woff2'),
        url('./Assets/Fonts/Play-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Play';
    src: url('./Assets/Fonts/Play-Bold.woff2') format('woff2'),
        url('./Assets/Fonts/Play-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Play', sans-serif;
    list-style: none;
    text-decoration: none;
    outline: none;
    background-color: #000;
}

body::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;

}

body::-webkit-scrollbar-track {
    background-color: transparent;
}

body::-webkit-scrollbar-thumb {
    background-color: rgba(169, 169, 169, 0.5);
    outline: 1px solid slategrey
}

a {
    text-decoration: none;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.links {
    animation: fadeInLeft;
    animation-duration: 3s;
}

.scroll-bg {
    width: 100%;
    height: 100%;
    background: url("./Assets/Img/home-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.events-bg {
    width: 100%;
    height: 100vh;
    background: url("./Assets/Img/events-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.infra-page-bg {
    background: url("./Assets/Img/infra-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.texno-page-bg {
    background: url("./Assets/Img/texno-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.talim-bg {
    background: url("./Assets/Img/it-talim-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.startaps-bg {
    width: 100%;
    background: url("./Assets/Img/startaps-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.digitization-bg {
    background: url("./Assets/Img/digitalization-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

.business-bg {
    background: url("./Assets/Img/bussnes-page-bg.jpg") no-repeat;
    background-size: cover;
    background-attachment: fixed;
    color: #fff;
}

#fp-nav.fp-right {
    right: 105px !important;
}

#fp-nav.fp-right ul li span {
    background-color: #fff !important;
}

@media only screen and (max-width: 768px) {
    .scroll-bg {
        background-position: -700px;
    }

    .texno-page-bg {
        background-position: -500px;
    }

    .talim-bg {
        background-position: -900px;
    }

    .startaps-bg {
        background-position: -500px;
    }

    .digitization-bg {
        background-position: -700px;
    }

    .business-bg {
        background-position: -300px;
    }

    .infra-page-bg {
        background-position: -600px;
    }
}

.css-1d8n9bt {
    display: flex !important;
    justify-content: center !important;
    padding: 4px 8px !important;
}

.css-319lph-ValueContainer {
    position: relative !important;
    display: flex !important;
        align-items: center !important;
        justify-content: center !important;
        color: #fff !important;
}

.css-qc6sy-singleValue {
    position: relative;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #fff !important;
}

.css-ez7z0v-Placeholder {
    position: absolute !important;
    left: 38% !important;
    top: 13% !important;
}