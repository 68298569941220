@import "../../Assets/Scss/Style.scss";
@import "../../Assets/Scss/Colors.scss";

.events {
    height: 100vh;
    padding-top: 100px;
    @include flexStyle(center, center, column);

    &__asidemenu-wrap {
        position: fixed;
        top: 40%;
        left: 10%;
    }

    &__tell-wrap {
        position: fixed;
        top: 80%;
        right: 10%;
    }

    &__bg-wrap {
        position: relative;
        width: 800px;
        @include flexStyle(center, center, column);
        margin: 0 auto;
        margin-bottom: 100px;
    }

    &__bg-title {
        position: absolute;
        @include title(110px, 700, 155%);
        color: rgba(255, 255, 255, 0.04);
    }

    &__title {
        @include title(28px, 700, 32px);
    }

    &__list {
        @include flexStyle(center, center, none);
        grid-gap: 20px 20px;
        flex-wrap: wrap;

        img {
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item {
        position: relative;
        width: 300px;
        height: 230px;
        transition: 0.5s ease;
        cursor: pointer;
        border-radius: 10px;
    }

    &__item:nth-child(1) {
        width: 400px;
        height: 230px;

        img {
            width: 400px;
            height: 230px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item:nth-child(odd) {
        animation: zoomIn;
        animation-direction: 1s;
    }

    &__item:nth-child(even) {
        animation: zoomIn;
        animation-direction: 1s;
    }

    &__item:nth-child(2) {
        width: 300px;
        height: 230px;

        img {
            width: 300px;
            height: 230px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item:nth-child(3) {
        width: 400px;
        height: 230px;

        img {
            width: 400px;
            height: 230px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item:nth-child(4) {
        width: 230px;
        height: 230px;

        img {
            width: 230px;
            height: 230px;
            object-fit: cover;
            border-radius: 10px;
        }
    }

    &__item:nth-child(5) {
        width: 330px;
        height: 230px;

        img {
            width: 330px;
            height: 230px;
        }
    }

    &__item:nth-child(6) {
        width: 230px;
        height: 230px;

        img {
            width: 230px;
            height: 230px;
        }
    }

    &__item:nth-child(7) {
        width: 300px;
        height: 230px;

        img {
            width: 300px;
            height: 230px;
        }
    }

    &__overlay {
        @include flexStyle(center, center, column);
        padding: 20px;
        text-align: center;
        position: absolute;
        inset: 0;
        opacity: 0;
        transition: 0.5s ease;
        border-radius: 10px;
        backdrop-filter: blur(5px);
    }

    &__item:hover &__overlay {
        opacity: 1;
    }

    &__overlay-title {
        @include title(40px, 700, 60px);
        color: rgba(255, 255, 255, 0.5);
        margin-bottom: 50px;
    }

    &__overlay-desc {
        @include title(14px, 400, 16px);
        color: #fff;
    }

    &__overlay-title {
        transform: scale(0);
        transition: all 0.6s ease;
    }

    &__item:hover &__overlay &__overlay-title {
        transform: scale(1);
    }

    &__overlay-desc {
        transform: scale(0);
        transition: all 1s ease;
    }

    &__item:hover &__overlay &__overlay-desc {
        transform: scale(1);
    }
}

@media(max-width: 1190px) {
    .events {
        height: 100%;
        padding-top: 200px;

        
    &__asidemenu-wrap {
        display: none;
    }

    &__tell-wrap {
        display: none;
    }
    }
}

@media(max-width: 900px) {
    .events {
        &__bg-wrap {
            width: 500px;
        }

        &__bg-title {
            @include title(90px, 700, 155%);
        }

        &__item:nth-child(1) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(2) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(3) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(4) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(5) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(6) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }

        &__item:nth-child(7) {
            width: 400px;
            height: 230px;

            img {
                width: 400px;
                height: 230px;
            }
        }
    }
}

@media(max-width: 560px) {
    .events {
        &__bg-wrap {
            width: 300px;
        }

        &__bg-title {
            @include title(60px, 700, 155%);
        }
    }
}

@media(max-width: 440px) {
    .events {
        &__bg-wrap {
            width: 300px;
        }

        &__bg-title {
            @include title(50px, 700, 155%);
        }

        &__list {
            grid-gap: 60px;
        }

        &__item:nth-child(1) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }

        &__item:nth-child(2) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }
        &__item:nth-child(3) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }

        &__item:nth-child(4) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }

        &__item:nth-child(5) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }

        &__item:nth-child(6) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }

        &__item:nth-child(7) {
            width: 100%;
            height: 230px;

            img {
                width: 100%;
                height: 230px;
            }
        }
    }
}

@media(max-width: 368px) {
    .events {
        &__bg-wrap {
            width: 200px;
        }

        &__bg-title {
            @include title(45px, 700, 155%);
        }
    }
}