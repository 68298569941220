@import "../../Assets/Scss/Style.scss";

.form-curs {
    opacity: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(20px);
    z-index: 1;

    &__form {
        position: relative;
        @include flexStyle(center, center, column);
        padding: 70px;
        width: 500px;
        height: 700px;
        top: 50px;
        transition: all 0.7s;
        transform: scale(0);
        background: rgba(0, 0, 0, 0.3);
        border: 1px solid #FFFFFF;
        backdrop-filter: blur(35px);
        border-radius: 20px;
        pointer-events: auto;
        z-index: 11111;
    }

    &__input {
        @include formInput();
        font-size: 20px;
        color: #fff;
        transition: all 0.5s ease;
    }

    &__input:focus {
        border-bottom: 1.5px solid #6C63FF;
    }

    &__input::placeholder {
        font-size: 18px;
        color: #fff;
    }

    &__input {
        margin-top: 50px;
    }

    &__input+&__input {
        margin-top: 50px;
    }

    &__textarea {
        @include formInput();
        margin: 50px 0px;
        font-size: 20px;
        color: #fff;
        transition: all 0.5s ease;
    }

    &__textarea:focus {
        border-bottom: 1.5px solid #6C63FF;
    }

    &__textarea::placeholder {
        font-size: 18px;
        color: #fff;
    }

    &__select {
        padding: 10px 0px;
        font-size: 16px;
        outline: none;
        font-family: 'Play';
        width: 100%;
        color: #fff;
        border: none;
        border-bottom: 1px solid #cacaca ;
        background-color: transparent;
    }

    &__option {
        color: #6C63FF;
        padding: 5px;
    }

    &__btn {
        @include button();
        border: none;
        background: #6C63FF;
    }
}

.open--form {
    opacity: 1;

    .form-curs__form {
        transform: scale(1);
    }
}

@media only screen and (max-width: 580px) {
    .form-curs {
        &__form {
            padding: 40px;
            width: 400px;
            height: 600px;
        }
    }
}

@media only screen and (max-width: 430px) {
    .form-curs {
        &__form {
            padding: 50px 30px;
            width: 300px;
            height: 600px;
        }
    }
}




