@import "../../../Assets/Scss/Style.scss";
@import "../../../Assets/Scss/Colors.scss";

.resident {
    height: 100vh;

    &__content {
        width: 1000px;
        @include flexStyle(center, space-between, none);
        margin: 0 auto;
        margin-bottom: 100px;
    }

    &__title {
        @include title(24, 700, 155%);
        margin-bottom: 20px;
    }

    &__desc {
        @include title(24px, 400, 155%);
        max-width: 490px;
    }

    &__list {
        position: relative;
        @include flexStyle(center, center, none);
        height: 340px;
    }

    &__list::before {
        content: "";
        position: absolute;
        top: 5px;
        width: 1100px;
        height: 400px;
        background: url("../../../Assets/Icon/resident-ovall.svg") no-repeat;
        background-size: 1100px;
    }

    &__item {
        @include flexStyle(center, none, column);
        width: 250px;
        height: 200px;
        text-align: center;
    }

    &__item+&__item {
        margin-left: 30px;
    }

    &__item-header {
        @include title(50px, 700, 58px);
        margin-bottom: 40px;
    }

    &__item-desc {
        @include title(20px, 700, 23px);
    }
}

@media only screen and (max-width: 1190px) {
    .resident {
        height: 150vh;

        &__list {
            @include flexStyle(center, center, column);
            background-size: 1050px;
            background-image: none;
            background-position: 50px 0px;
            padding-top: 550px;
        }

        &__list::before {
            content: "";
            position: absolute;
            left: -8%;
            top: 64%;
            width: 1100px;
            height: 400px;
            transform: rotate(90deg);
            background: url("../../../Assets/Icon/resident-ovall.svg") no-repeat;
            background-attachment: fixed;
            background-size: 1100px;
        }

        &__item+&__item {
            margin-left: 0px;
            margin-top: 130px;
        }
    }
}

@media only screen and (max-width: 1020px) {
    .resident {
        &__content {
            width: 800px;
        }
    }
}

@media only screen and (max-width: 850px) {
    .resident {
        height: 180vh;

        &__content {
            width: 100%;
            @include flexStyle(center, center, column);
            text-align: center;
        }

        &__title {
            @include title(24, 700, 155%);
            margin-bottom: 20px;
        }

        &__desc {
            @include title(24px, 400, 155%);
            max-width: 100%;
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: 768px) {
    .resident {

        &__content {
            width: 100%;
        }

        &__desc {
            @include title(20px, 400, 155%);
            margin-bottom: 40px;
        }

        &__list::before {
            left: -34%;
            top: 64%;
        }
    }
}

@media only screen and (max-width: 580px) {
    .resident {
        &__list::before {
            left: -70%;
        }
    }
}

@media only screen and (max-width: 580px) {
    .resident {
        &__list::before {
        display: none;
        }
    }
}

@media only screen and (max-width: 368px) {
    .resident {
        &__box {
            img {
                width: 100%;
                height: auto;
            }
        }


        &__desc {
            @include title(18px, 400, 155%);
            margin-bottom: 40px;
        }
    }
}